import React, { useEffect, useRef } from "react";
import '../styles.css?v=1.1';
import sol from "../images/solplanet.webp";
import jinko from "../images/Jinko.webp";
import Novasys from "../images/Nova.webp";
import solex from "../images/solex-1.webp";


const Video1 = () => {
 
  function MobileVideoPlay(){
    const videotag = document.getElementById('source-container')
    const videos =  document.getElementById("myVideo")
    if(window.innerWidth >= 767){
      videotag.setAttribute('src',require("../images/smallVideo-second.mp4"))
    }
    else{
      videotag.setAttribute('src',require("../images/mobileVideo-second.mp4"))
    }
    videos.load();
      }
  
  

  useEffect(()=>{
    window.addEventListener('resize', MobileVideoPlay);
    MobileVideoPlay();
    // return ()=>{
    //   window.removeEventListener('resize', MobileVideoPlay);
    // }
  })




  return (
    <>
      <div className="App">
        <div style={{ position: "relative" }}>
          <video
            id="myVideo"
            style={{ width: "100%", margin: "0 auto" }}
            autoPlay
            preload="metadata"
            loop
            muted
            playsInline
            controls={false}
            
          >
            <source src={MobileVideoPlay} type="video/mp4" id="source-container"   />
            <track src={MobileVideoPlay} kind="captions" srcLang="en" label="english_captions"/>
          </video>
        </div>
      </div>

      <div
        className=" container-fluid d-flex"
        style={{
        
          borderRadius:"20px",
          width: "85%",
          marginBottom: "30px",
          marginTop: "30px",
          justifyContent: "space-evenly",
          backgroundColor: "#282438",
          
        }}
      >
        <img
          className="img-fluid"
          src={jinko}
          alt="jinko-logo"
          title="jinko-logo"
          style={{ width: "14%" ,height:'auto'  }}
        />
        <img
          className="img-fluid"
          src={solex}
          alt="solex-logo"
          title="solex-logo"
          style={{ width: "14%",height:'auto'  }}
        />
        <img
          className="img-fluid"
          src={Novasys}
          alt="Novasys-logo"
          title="Novasys-logo"
          style={{ width: "14%",height:'auto'  }}
        />
        <img
          className="img-fluid solplanet"
          src={sol}
          alt="solplanet-logo"
          title="solplanet-logo"
          style={{ width: "14%",height:'auto'  }}
        />
      </div>
    
      </>
  );
}

export default Video1;
