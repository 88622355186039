import React from "react";
import Navbar from "../Navbar";
import Footer from "../footer";
import "./mainsolplanet.css";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Sp from "../../images/1.webp"
import Vision from "./visionsetion";
import solarplate from "../../images/solarpanel.webp";
import plat2 from "../../images/2.webp"
import plat3 from "../../images/3.webp"
import footer_logo from "../../images/oneklick-black-logo.webp"
import Solplanetsheet from "./Solsheet"

import AOS from "aos";

const Mainsolplanet = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [product, setProduct] = useState("");
  const [message, setMessage] = useState("");
  const [validation, setvalidation] = useState(false);

  


  const mailvalidation = (value) => {
    if (/^^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return true;
    } else {
      return false;
    }
  };
  const sendEmail = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (name === "" && email === "" && contact === "" && product == "") {
        Swal.fire({
          icon: "error",
          title: "Invalid Name",
          text: "Please Fill The All Details",
        });
        return;
      } else if (name === "") {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter Your Name",
        });
        return;
      } else if (email === "") {
        Swal.fire({
          icon: "error",
          // title: 'Fill The Ema',
          text: "Please Enter Your Email",
        });
        return;
      } else if (!mailvalidation(email)) {
        Swal.fire({
          icon: "error",
          text: "Invalid Email",
        });
        return;
      } else if (contact.length < 10 || !/^[9876]\d{9}$/.test(contact)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Invalid Contact Number",
        });
        return;
      } else if (product === "") {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Select The Module ",
        });
        return;
      }
    }
    try {
      $.ajax({
        url: "https://appj.scaleedge.in/register",
        type: "POST",
        data: {
          subject: "Enquiry For Module",
          message: message,
          name: name,
          email: email,
          contact: contact,
          product: product,
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success: function (response) {
          console.log("Email sent successfully");
          Swal.fire("Good job!", "Mail Send Successfully", "success");
          console.log(response);
          setName("");
          setEmail("");
          setContact("");
          setProduct("");
        },
        error: function (err) {
          console.log(err);
          //alert(11);
          // alert(err.responseText);
        },
      });
      setvalidation(true);
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(
    ()=>{
      AOS.init({duration:1000})
    }
  )

  useEffect(()=>{
    window.scrollTo(
      {
        top:0,
        left:0,
        behavior:'instant',
      }
    )
    
   },[])
  return (
    <div>
    
        <Helmet>
          <title>
          Solplanet Inverter Authorized Distributor | OneKlick
          </title>
          <meta
            name="description"
            content="OneKlick is authorized solplanet inverters distributor in india. Buy top solar inverters In India including string inverters, Solplanet solar Inverters, single-phase inverters and three-phase inverters."
          />
          <meta
            name="keywords"
            content=" Solplanet Distributor, Solplanet Inverter, Solplanet Inverter in India, Inverter Distributor in India, Best Inverter in India, Top 5 Inverters, Best String Inverter, String Inverter with Shadow Management, Best Inverter for 10 Years Warranty"
          />
          <link rel="canonical" href="https://oneklick.in/solplanet" />
          <meta
            name="twitter:title"
            content="Solplanet Inverter Authorized Distributor | OneKlick"
          />
          <meta
            name="twitter:description"
            content="OneKlick is authorized solplanet inverters distributor in india. Buy top solar inverters In India including string inverters, Solplanet solar Inverters, single-phase inverters and three-phase inverters."
          />
          <meta
            property="og:title"
            content="Solplanet Inverter Authorized Distributor | OneKlick"
          />
          <meta
            property="og:description"
            content="OneKlick is authorized solplanet inverters distributor in india. Buy top solar inverters In India including string inverters, Solplanet solar Inverters, single-phase inverters and three-phase inverters."
          />
          <meta property="og:url" content="https://oneklick.in/solplanet" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "Where can we get Solplanet inverters ?",
              text: "At Oneklick, you can get Solplanet inverters with features, solar inverters prices, and specifications. ",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "Why Solplanet For Solar Inverters In India?",
              text: "Top solar inverters in the Indian market, Solplanet stands as a reputed brand. SolPlanet inverters are designed to deliver exceptional performance even in challenging conditions thanks to features like Shade Sol technology and maximum point tracking.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "What is the Price of Solplanet Inverter in India?",
              text: "The price of a Sol Plane inverter can vary widely depending on several factors, including the type of inverters, its capacity, and additional features. ",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "What Inverter Is Right for My Home?",
              text: "OneKlick helps you select the right inverter for your solar power system. This can have a significant impact on system performance, energy production, and overall efficiency. The inverter depends on your home and energy needs.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "What is Solplanet Inverter Efficiency?",
              text: "Solplanet inverter efficiency is an important factor to consider when evaluating and designing a solar power system. Inverter efficiency is usually expressed as a percentage and shows how effectively the inverter performs its primary function.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "Why do we need Solplanet Solar inverters?",
              text: "Solar panels generate DC electricity, but home appliances and the grid operate on AC electricity. A solar inverter is necessary to convert DC electricity into usable AC electricity.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "What is a Hybrid Solar Inverter?",
              text: "Hybrid solar inverter combines the functions of a standard solar inverter with a battery inverter/charger. It stores excess solar energy in batteries for use during periods of low sunshine or during power cuts.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "What is the difference between string inverters and microinverters?",
              text: "String inverters are usually installed in a central location and connect multiple solar panels in a series. But Microinverters are installed on each individual panel and convert DC to AC at the panel level. It provides better performance in cases of shading or panel-level mismatch.",
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Question",
              name: "Can I connect my Solplanet inverter to the grid?",
              text: "Yes, grid-tied inverters allow you to connect your solar system to the electrical grid",
            })}
          </script>
        </Helmet>
    
      <Navbar />
      <div className="col-md-12 main-solplanet-container">
        <div className="row product-row">
          <div className="col-md-8 product-left-container">
            {/* <h1 className="product-title">Authorised Distributor of</h1> */}
            <div className="row">
              <div className="col-md-12">
                <div className="title-header" id="title-head" data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true">
                  {/* <h1 className="fs-1">JINKO SOLAR</h1> */}
                  <h1 className="fs-1">SOLPLANET INVERTER</h1>
                  <p className="p-2  m-0 fs-4 fw-bolder inv-subtit" >
                    Beat the Shadows, Boost Your Power <br /> with SHADESOL in
                    Our Inverters
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 product-right-container">
            <div className="product-right-content">
              <form className="needs-validation form-container" noValidate>
                <p
                  className="head-title text-center mb-3 p-0"
                  style={{ fontSize: "30px", color: "white" }}
                >
                  {" "}
                  <p className="fw-5 fs-4 fw-light p-0 mb-0 lh-sm">
                    Contact Form
                  </p>
                </p>
                <div className="form-group me-class">
                  <input
                    type="text"
                    className="form-control product-form-control"
                    id="validationCustom01"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    autoComplete="off"
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group product-form-group  me-class">
                  <input
                    type="email"
                    className="form-control product-form-control"
                    id="validationCustom03"
                    placeholder="Email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group product-form-group  me-class">
                  <input
                    type="number"
                    className="form-control product-form-control"
                    id="validationCustom05"
                    placeholder="Number"
                    required
                    name="number"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
                <div className="form-group product-form-group  me-class">
                  <input
                    type="text"
                    className="form-control product-form-control"
                    id="validationCustom04"
                    placeholder="Requirement"
                    required
                    name="module"
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                  />
                </div>
                <div className="col-md-12 message-con  me-class d-none ">
                  <input
                    type="text"
                    class="form-control  "
                    name="message"
                    value={message}
                    placeholder="MESSAGE"
                  />
                </div>
                {/* <input type="text" readOnly className="col-md-12 rounded-3 readvalue" style={{fontSize:'15px'}} value={message} /> */}
                <button
                  type="submit"
                  onClick={sendEmail}
                  className="btn btn-dark btn-md mt-3 me-class product-btn submitbtns"
                  style={{ borderRadius: "10px", cursor: "pointer" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-md-12 header-section  
         flex-column  d-flex justify-content-center align-items-center "
        style={{ marginTop: "50px" }}
      >
        <h2
          className="col-md-9  text-center px-2 "
          data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true"
        >
         What is Shadesol Technology in Solplanet Inverters- A Case Study
        </h2>
        <div
          className="col-md-9 text-start px-4 py-1 mt-2 text-dark mb-2  "
          data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true"
        >
          <ul  className="fs-6 fw-bold">
            <li>
              Sampe Size - 10 Mono Parc Modules
            </li>
            <li>
              Shadow Casted - 4 Modules
            </li>
            <li>
              Simple String Design
            </li>
          </ul>
        </div>
        {/* ShadeSol Field */}
        <div className="shadesol-container col-md-12 col-12 col-lg-12">
        <div
        className="col-md-12 header-section 
         flex-column  d-flex justify-content-center align-items-center  mb-0"
        style={{ marginTop: "50px" }}
      >
        <h2
          className="col-md-9  text-center px-2 m-3 mb-0"
          data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true"
          style={{background:'#262645'}}
        >
         CASE STUDY : HEAVY SHADING of 4 MODULES 
        </h2>
        </div>
        <div className="col-lg-7 col-12 col-md-9 mt-3 px-4 " data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true">
          <div className="row">
            <div className="left-shade-container col-md-7 p-0 mb-2  ">
              <div className="col-md-9 col-lg-10">
                <div className="w-100">
                  <h3 className="text-capitalize my-2">
                    {" "}
                    Normal Production without shading{" "}
                  </h3>
                </div>
              </div>
              <div className="img-shade my-2 text-start">
                <img
                  src={Sp}
                  className="img-fluid"
                  width={"450px"}
                  title="Shadesol-Technology"
                  alt="Shadesol-Technology"
                  
                />
              </div>
            </div>
            <div className="right-shade-container col-md-5 col-lg-4 m-auto">
              <div className="production-container px-2">
                <div className="inner-production col-md-11 my-3 p-2">
                  <p className="m-0 fw-bolder text-dark ">Production</p>
                  <p className="m-0 fw-bold text-dark " style={{fontSize:'15px'}}>
                    10 Nos x 8A X 30V Total Power = 2400W
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12 col-md-9  px-4 mt-2 " data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true">
          <div className="row">
            <div className="left-shade-container col-md-7 p-0 mb-2 ">
              <div className="col-md-9 col-lg-10">
                <div className="w-100">
                  <h3 className=" text-capitalize mt-3">
                    {" "}
                    Production with shade - Normal Inverter{" "}
                  </h3>
                </div>
              </div>
              <div className="img-shade my-2 text-start">
                <img
                  src={plat2}
                  className="img-fluid"
                  width={"450px"}
                  alt="Inverter"
                  title="Inverter"
                  
                />
              </div>
            </div>
            <div className="right-shade-container col-md-5 col-lg-4 m-auto">
              <div className="production-container  px-2">
                <div className="inner-production col-md-11 my-3 p-2">
                  <p className="m-0 fw-bolder p-0 text-dark">Production</p>
                  <p className="m-0 fw-bold p-0  text-dark" style={{fontSize:'13px'}}>
                    4Nos x 2.5A x 30V = 300W +6Nos x 2.5A X 33V= 495W Total
                    Power = 795W
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12 col-md-9  px-4  mt-2" data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true">
          <div className="row">
            <div className="left-shade-container col-md-7 p-0 mb-2 ">
              <div className="col-md-9 col-lg-10">
                <div className="w-100">
                  <h3 className=" text-capitalize my-2">
                    {" "}
                    Production with Optimizer 1:1{" "}
                  </h3>
                </div>
              </div>
              <div className="img-shade my-1 text-start">
                <img
                  src={plat3}
                  className="img-fluid"
                  width={"450px"}
                  title="Shadesol-Technology-Optimizer"
                  alt="Shadesol-Technology-Optimizer"
                  
                />
              </div>
            </div>
            <div className="right-shade-container col-md-5 col-lg-4 m-auto">
              <div className="production-container px-2">
                <div className="inner-production col-md-11 my-3 p-2">
                  <p className="m-0 fw-bolder p-0 text-dark">Production</p>
                  <p className="m-0 fw-bold p-0 text-dark" style={{fontSize:'13px'}}>
                    4Nos x 8A x 9.375V = 300W +6Nos x 8A X 30V= 1440W Total
                    Power = 1740W
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12 col-md-9  px-4 mt-2 " data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true">
          <div className="row">
            <div className="left-shade-container col-md-7 p-0 mb-2 ">
              <div className="col-md-9 col-lg-10">
                <div className="w-100">
                  <h3 className="text-capitalize my-2">
                    {" "}
                    Production with ShadeSol Optimization{" "}
                  </h3>
                </div>
              </div>
              <div className="img-shade my-1 text-start">
                <img
                  src={solarplate}
                  className="img-fluid"
                  width={"450px"}
                  title="Production-with-ShadeSol-Optimization"
                  alt="Production-with-ShadeSol-Optimization"
                  
                  
                />
              </div>
            </div>
            <div className="right-shade-container col-md-5 col-lg-4 m-auto">
              <div className="production-container px-2">
                <div className="inner-production col-md-11 my-3 p-2">
                  <p className="m-0 fw-bolder p-0 text-dark">Production</p>
                  <p className="m-0 fw-bold p-0 text-dark" style={{fontSize:'13px'}}>
                    4Nos x 8A x (-3)V = -96W +6Nos x 8A x 30V = 1440W Total
                    Power = 1344W
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 left-shade-container mt-4 col-md-8 p-0 mb-2 px-3 d-flex justify-content-center align-items-center  ">
              <div className="col-md-12 col-lg-11">
                <div className="w-100 ">
                  <h3 className="fw-normal caution-context fs-6">
                  
                   <b> Conclusion :</b> Solplanet Inverters with its Shadesol technology produces almost equivalent to optimizer/micro inverters in heavy shaded condition as well. 
ShadeSol is very well able to mitigate the losses arising out of Shadows and other soiling losses. 
                  </h3>
                </div>
              </div>
              </div>
        
        </div>
        <Vision /> {/* Card Section */}
        <div className="col-md-9 header-section col-12 mb-0 planet-div text-center">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"
            className="px-4"
          >
        DataSheet
          </h2>
          {/* <p  data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true">
           OneKlick is the authorized <b> Solplanet inverter distributor in India </b>. We provides a wide range of top-quality solar inverters in India : <li> ASW LT- G3 series</li>
            <li>Three Phase Hybrid Inverter </li><li> Single Phase Hybrid Inverter </li> <li> SOL
            Evpower Series </li>  <li> Single Phase String Inverter </li> <li> String Inverter
            with shadow management </li> 
<li> Solplanet inverters are suitable for both <b> residential and commercial </b> use, catering to various solar energy needs.</li>
<li>These inverters are <b> user-friendly </b> and come equipped with advanced features.</li>
<li>They are designed to be <b> energy-efficient </b> and  <b>contribute </b>to a cleaner, <b> greener future.</b></li>
<li> Solplanet's string inverters are particularly noteworthy for their <b> shade management capabilities</b>.</li>

<li> Solplanet solar inverters offering cutting-edge technologies like <b> Shadesol Technology, SMA Assistant, and Maximum Power Point </b> Trackers to ensure optimal energy production even in challenging conditions</li>
          </p> */}
        </div>
        
        {/*  */}
        <Solplanetsheet/>
      </div>
   
                      
    
        
     
      
      <Footer />

      
    </div>
  );
};

export default Mainsolplanet;
