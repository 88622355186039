import React from "react";
import Modal from "react-modal";
import "../components/custommodal.css";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import modelimg  from "../images/model_img_s.webp"
import ReactModal from "react-modal";
import App from "../App";
const CustomModal = ({ isOpen, closeModal }) => {

  const handleCloseModal = () => {
    closeModal();
  };

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [product, setProduct] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);


  const mailvalidation =(value)=>{
    if(/^^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)){
      return true
    }
    else{
      return false
    }
  }


  const sendEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (email === "" && name === "" && product === "" && contact === "" ) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter All Details",
        });
        return ;
      } else if (name === "" || /\d/.test(name)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter Your Name",
        });
        return ;
      } 
      else if (email === "") {
        Swal.fire({
          icon: "error",
          // title: 'Caution',
          text: "Please  Enter Your Email",
        });
        return ;
      } 
      if (!mailvalidation(email)) {
        Swal.fire({
          icon: "error",
          text: "Invalid Email",
        });
        return ;
      }else if (product === "") {
        Swal.fire({
          icon: "error",
          // title: 'Fill The Module',
          text: "Enter  Your Requirement",
        });
        return ;
      } else if (contact.length < 10 || !/^[9876]\d{9}$/.test(contact)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Invalid Contact Number",
        });
        return;
      }
      
    }
    try {
      $.ajax({
        url: "https://appj.scaleedge.in/register",
        type: "POST",
        data: {
          email: email,
          name: name,
          contact: contact,
          product: product,
          message: message,
          subject: "Query Form",
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success: function (response) {
          console.log("Email sent successfully");
          Swal.fire("Good job!", "Mail Send Successfully","success");
          console.log(response);
          setEmail("");
          setName("");
          setProduct("");
          setContact("");
          setMessage("");
          setValidated(false);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
    }
    setValidated(true);
  };

  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      minWidth:'25vw',
      height:'600px',
      overflow:'hidden',
      border:"none",
     
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    }
  };

ReactModal.setAppElement("body")
  return (

    <div className="cm-align  ">
    <Modal isOpen={isOpen}  contentLabel="Example Modal" onRequestClose={handleCloseModal}  className=" bg-light border-opacity-0 col-md-2 modal-class-style " style={customStyles}  >
      <div className="modal-form " style={{zIndex:999}}>
        <img src={modelimg} className="img-fluid m-image" alt="custom-img" width="100%" title="custom-img" style={{height:'270px',objectFit:"fill"}} />
        <form className="form-content ">
          <div className="modal-btn ">
            <i
              class="fa-regular fa-circle-xmark  x-cross mx-3"
              onClick={closeModal}
            ></i>
          </div>
          <div className="row" style={{ marginTop: '20px' }}>
            <div className="col-md-12 modal-field">
              <input
                type="text"
                class="form-control"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="NAME"
                required
              />
            </div>
            <div className="col-md-12 modal-field">
              <input
                type="email"
                class="form-control"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-MAIL"
                required
              />
            </div>
            <div className="col-md-12 modal-field">
              <input
                type="text"
                class="form-control"
                name="product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                placeholder="PRODUCT REQUIRED"
                required
              />
            </div>
            <div className="col-md-12 modal-field">
              <input
                type="number"
                class="form-control"
                name="contact"
                value={contact}
                id="contactcontact"
                onChange={(e) => setContact(e.target.value)}
                placeholder="CONTACT NUMBER"
                required
              />
            </div>
            {/* <div className="col-md-12 modal-field">
              <input
                type="text"
                class="form-control"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="MESSAGE"
              />
            </div> */}
            <div className="col-md-12 modal-field modal-btn-field mt-4">
              <button
                type="submit"
                className="btn text-light mb-2 btn-sm "
                onClick={sendEmail}>
                Send Query
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
    </div>
  );
};

export default CustomModal;
