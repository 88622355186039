import React from "react";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "@splidejs/react-splide/css/sea-green";
// import test_img from "../../images/harsh.webp";
// import test_img2 from "../../images/harvinder.webp";
// import test_img3 from "../../images/Simran.webp";
// import test_img4 from "../../images/Sharad.webp";
import Turnel from "./Campain_turnel";
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import fgmr from "../../images/solex-solar.webp";
import fgml from "../../images/solex-solar-panels.webp";
import module from "../../images/jinko-product.webp";

export default function Campain_jinko() {
  const differenceData = [
    {
      imgSrc: module,
      title: "Customer Centric Approach",
      content:
        "We always put our customers first and work to provide the best possible experience. We’re constantly improving our process and service, and welcome feedback to help us grow. Our interaction with our clients is a great way to keep up with the latest news and announcements, as well as learn more about how we operate.",
      alt: "Customer-Centric-Approach",
      title: "Customer-Centric-Approach",
    },
    {
      imgSrc: module,
      title: "Strong Distribution Network",
      content:
        "We have a strong distribution network in India that helps us get our solar products to our customers quickly and efficiently. We have a dedicated team of professionals who handle all aspects of the distribution process, from warehousing to transportation. This ensures that our products are delivered on time and in perfect condition.",
      alt: "Strong-Distribution-Network",
      title: "Strong-Distribution-Network",
    },
    {
      imgSrc: module,
      title: "Team",
      content:
        "At OneKlick, we have a dedicated team of experienced professionals who are knowledgeable about the latest technology and have vast experience of decades having worked in Solar Industry. Our staff comprises of combination of youth and experience and our director of business development is an IIT Kharagpur alumnus.",
      alt: "Team-img",
      title: "Team-img",
    },
    {
      imgSrc: module,
      title: "Affordable",
      content:
        "We are committed to providing affordable, reliable solar modules to homes and businesses across India. We are constantly innovating and expanding our product range, so that we can offer you the latest and best technology available at the best prices. Our goal is to make going solar as easy and affordable as possible for everyone.",
      alt: "Affordable",
      title: "Affordable",
    },
    {
      imgSrc: module,
      title: "Mission",
      content:
        "We are on a mission to provide renewable energy solutions that are affordable and reliable in India. We believe that solar energy is the key to a sustainable future, and we are committed to making it more accessible to everyone. Our team of experts are always available to guide and advise you on the best product for your project.",
      alt: "Mission",
      title: "Mission",
    },
    {
      imgSrc: module,
      title: "Vision",
      content:
        "We at OneKlick Techno Renewable Pvt. Ltd strongly believe that solar energy is the future. We are committed to providing our customers with the best products and services in the solar industry and strive to be the leading solar module distributor in India. We are constantly innovating and expanding our business to better serve our customers.",
      alt: "Vision",
      title: "Vision",
    },
  ];

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // document.addEventListener('DOMContentLoaded', function () {
  //   var splide = new Splide('#splideCon', {
  //     perPage: perPage,
  //     gap: '50px',
  //   });
  // });
  

  const [perPage, setPerPage] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setPerPage(1);
      } else {
        setPerPage(2);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section>
        <div className="col-md-12 ps-first-container">

          <div className="child-container child-container-2 m-0">
            <div className="col-md-8 header-section ">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
              >
                Solex Solar National Stock Exchange Listed Company
              </h2>
              <p
                data-aos-duration="800"
                data-aos="fade-left"
                data-aos-once="true"
                className="px-4"
              >
                <li>Solex solar panels 27 Years Performance Warranty</li>
                <li>
                  Solex modules Lowest Temperature Co-efficient : -0.32% / Deg
                  Celsius
                </li>
                <li>Solar modules increased energy yield - Up to 30%</li>
                <li>Solex solar panels Higher Generations in Warm Climate</li>
                <li>Unmatched Safety - Class II Electrical Safety</li>
                <li>Global Production House</li>
              </p>

              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
                className="my-5"
              >
                Solex Solar Panels Range In India
              </h2>
              <p
                data-aos-duration="800"
                data-aos="fade-left"
                data-aos-once="true"
                className="px-4"
              >
                Solex Solar is a well-known solar panel manufacturer known for
                offering a wide range of solar modules in India. Their solar
                product range is suitable for a variety of applications ranging
                from residential to commercial and industrial.
                <li className="mt-3">
                  <b>Polycrystalline Solar Panels:</b> Solex Polycrystalline
                  Solar modules are made from multiple silicon crystals,
                  offering good efficiency and performance in various weather
                  conditions.
                </li>
                <li>
                  <b>Monocrystalline Solar Panels:</b> Solex Monocrystalline
                  solar modules are highly efficient and are made from
                  single-crystal silicon.
                </li>
                <li>
                  <b>Rooftop Solar System:</b> Rooftop is Ideal for residential
                  homes, commercial buildings, and industrial facilities, where
                  space is limited, and high efficiency is essential. Rooftop
                  solar systems come in various sizes and configurations,
                  including grid-tied systems, off-grid systems,
                  and hybrid systems.
                </li>
              </p>
            </div>
          </div>
          <Turnel />


          <div className="child-container m-0">
            <div className="col-md-8 header-section">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
              >
                {" "}
                Tapi 530-560Wp Solar Module
              </h2>
              <p
                data-aos-duration="800"
                data-aos="fade-left"
                data-aos-once="true"
                className="px-4"
              >
                <li>Tapi Solar panels have 12 Years Product Warranty</li>
                <li>27 Years Linear Performance Warranty</li>
                <li>Solar Cell - 144 Half Cell</li>
                <li>Solar Panels Efficiency - Upto 21.68%</li>
                <li>Power - Upto 560Wp</li>
                <li>Tolerance : Upto +2.99Wp Positive</li>
                <li>Weight : 28.0 Kg</li>
              </p>
              {/* <h2 data-aos-duration="800"  data-aos="fade-right" data-aos-once="true" style={{marginTop:'100px'}}>Bifacial with Transparent Backsheet Technology</h2> */}
            </div>
          </div>
          <div className="child-container-second mb-4">
            <div className="col-md-10 child-container-secondary">
              <div className="row" >
                <div className="col-md-6 primary-container">
                  <img
                    src={fgmr}
                    alt="bifacial-image"
                    title="bifacial-image"
                    className="module-images img-fluid "
                    data-aos-duration="800"
                    data-aos="zoom-in-right"
                    data-aos-once="true"
                  />
                </div>
                {/* <div className="col-md-1">
              
            </div> */}

                <div className="col-md-6 secondary-container">
                  <img
                    src={fgml}
                    alt="bifacial-image-2"
                    title="bifacial-image-2"
                    className="module-images img-fluid "
                    data-aos-duration="800"
                    data-aos="zoom-in-left"
                    data-aos-once="true"
                  />
                </div>

                <div className="col-md-12 header-section d-flex justify-content-center">
                  <h2
                    className="col-md-10 col-12"
                    data-aos-duration="800"
                    data-aos="fade-right"
                    data-aos-once="true"
                  >
                    {" "}
                    Solex Solar Modules Benefit and Feature
                  </h2>
                </div>

                <div className="">
                  <div className="row">
                    <div className="col-md-4  header-content-first px-4 ">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa-solid fa-regular fa-heart"></i> Increased
                          Savings
                        </h3>
                        <p>
                          With our cutting-edge solar panels, you can bask in
                          the warm glow of not just the sun but also substantial
                          savings on your energy bills.
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa fa-lock"></i>Hassle Free Installation
                        </h3>
                        <p>
                          Effortless Installation, Endless Delight! Say goodbye
                          to worries and embrace hassle-free solar panel
                          installation that brings you closer to a greener,
                          brighter tomorrow.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4  header-content-first px-4">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa fa-link"></i>Minimum Maintenance
                        </h3>
                        <p>
                          Rejoice in the simplicity of green energy that powers
                          your home with love, leaving you with more time to
                          cherish life's precious moments.
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa fa-message"></i> Green, Clean &
                          Absolutely safe
                        </h3>
                        <p>
                          Our solar panels not only empower your home with
                          renewable energy but also fill your heart with peace,
                          knowing you're making a positive impact on the
                          environment.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4  header-content-first px-4">
                      <div className="header-content-first-section">
                        <h3>
                          <i class="fa-solid fa-hand-pointer mr-2"></i>Long Life
                          Expectancy
                        </h3>
                        <p>
                          Feel the joy of investing in a brighter future, where
                          each day is illuminated by the unwavering glow of
                          clean energy.{" "}
                        </p>
                      </div>
                      <div className="header-content-second-section">
                        <h3>
                          <i class="fa-sharp fa-solid fa-eye-slash"></i> Tension
                          Free Technology
                        </h3>
                        <p>
                          Experience the bliss of worry-free technology with our
                          solar panels. No more fretting over rising energy
                          costs or environmental impact.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 testimonial-section m-0">
            <div className="text-aling-center d-flex justify-content-center">
              <div className="testimonial-main-container col-md-8"></div>
            </div>
            <div className="testi-content-container col-md-12">
              <div className="client-testi col-md-2 ">
                <p>Client Testimonial</p>
              </div>
            </div>
            <div className="testi-header-section">
              <h4>
                Uplifting Spirits, Inspiring Stories: <br /> Heartwarming
                Testimonials from Our Valued Clients{" "}
              </h4>
            </div>

            <Splide
              aria-label="My Favorite Images"
              options={{ perPage: perPage, gap: "50px" }}
              id="splideCon"
            >
              <SplideSlide>
                <div className="center-containers">
                  <div
                    className="testimonial-first-container col-md-10"
                    id="firstcontainer"
                  >
                    <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Harsh Jondle</h5>
                        <p style={{ fontSize: "19px" }}>JD Enerbiz Pvt. Ltd</p>
                        <p>
                          Oneklick is solar panels distributor company in india.
                          We Highly satisfied! Excellent solar panels and great
                          service. Oneklick is best distributor! We are Highly
                          recommend for solar modules in India.
                        </p>{" "}
                        <br /> <br />
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img}
                          alt="client-1"
                          title="client-1"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div
                  className="testimonial-first-container col-md-10"
                  id="firstcontainer"
                >
                  <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Harvinder Singh</h5>
                      <p style={{ fontSize: "19px" }}>
                        Solidus Techno Power Pvt.
                      </p>
                      <p>
                        Oneklick is Reliable solar service, its have provide
                        top-quality panels, and excellent prices. Highly
                        recommend this solar panel distributor!
                      </p>{" "}
                      <br /> <br /> <br />
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img2}
                        alt="client-2"
                        title="client-2"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="center-containers">
                  <div
                    className="testimonial-first-container col-md-10"
                    id="firstcontainer"
                  >
                    <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Simran Singh</h5>
                        <p style={{ fontSize: "19px" }}>
                          Bliss Solar Power Pvt.
                        </p>
                        <p>
                          Exceptional solar module distributor! OneKlick offer a
                          wide range of high-quality Solar modules, efficient
                          service, and competitive Solar panel prices. A
                          reliable partner for all solar needs.
                          Highly recommended!
                        </p>{" "}
                        <br />
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img3}
                          alt="client-3"
                          title="client-3"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  className="testimonial-first-container col-md-10"
                  id="firstcontainer"
                >
                  <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Sharad Dutta Acharya</h5>
                      <p style={{ fontSize: "19px" }}>
                        Bull Power Energy Pvt. Ltd
                      </p>
                      <p>
                        Best Solar Panels distributor in India! Reliable supply
                        of high-quality solar modules. Efficient service, prompt
                        delivery, and affordable prices. A trusted partner for
                        all solar needs.
                      </p>{" "}
                      <br /> <br />
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img4}
                        alt="client-4"
                        title="client-4"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div> */}





          {/* <div className="col-md-12 testimonial-section m-0">
      <div className="text-align-center d-flex justify-content-center">
        <div className="testimonial-main-container col-md-8"></div>
      </div>
      <div className="testi-content-container col-md-12">
        <div className="client-testi col-md-2 ">
          <p>Client Testimonial sad</p>
        </div>
      </div>
      <div className="testi-header-section">
        <h4>
          Uplifting Spirits, Inspiring Stories: <br /> Heartwarming Testimonials
          from Our Valued Clients
        </h4>
      </div>

      <Splide
        options={{ perPage: perPage, gap: '50px' }}
        id="splideCon"
        className="testimonial-slider"
      >
        <SplideSlide>
          <div className="center-containers">
            <div
              className="testimonial-first-container col-md-10"
              id="firstcontainer"
            >
               <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Harsh Jondle</h5>
                        <p style={{ fontSize: "19px" }}>JD Enerbiz Pvt. Ltd</p>
                        <p>
                          Oneklick is solar panels distributor company in india.
                          We Highly satisfied! Excellent solar panels and great
                          service. Oneklick is best distributor! We are Highly
                          recommend for solar modules in India.
                        </p>{" "}
                        <br /> <br />
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img}
                          alt="client-1"
                          title="client-1"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                        />
                      </div>
                    </div>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div
            className="testimonial-first-container col-md-10"
            id="firstcontainer"
          >
              <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Harvinder Singh</h5>
                      <p style={{ fontSize: "19px" }}>
                        Solidus Techno Power Pvt.
                      </p>
                      <p>
                        Oneklick is Reliable solar service, its have provide
                        top-quality panels, and excellent prices. Highly
                        recommend this solar panel distributor!
                      </p>{" "}
                      <br /> <br /> <br />
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img2}
                        alt="client-2"
                        title="client-2"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="center-containers">
            <div
              className="testimonial-first-container col-md-10"
              id="firstcontainer"
            >
               <div className="row">
                      <div className="col-md-7 starter-details">
                        <h5>Simran Singh</h5>
                        <p style={{ fontSize: "19px" }}>
                          Bliss Solar Power Pvt.
                        </p>
                        <p>
                          Exceptional solar module distributor! OneKlick offer a
                          wide range of high-quality Solar modules, efficient
                          service, and competitive Solar panel prices. A
                          reliable partner for all solar needs.
                          Highly recommended!
                        </p>{" "}
                        <br />
                        <div className="star-container">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-regular fa-star"></i>
                        </div>
                      </div>
                      <div className="col-md-5 remake m-auto">
                        <img
                          src={test_img3}
                          alt="client-3"
                          title="client-3"
                          id="firstimg"
                          width={"93%"}
                          height={"100%"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
            </div>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div
            className="testimonial-first-container col-md-10"
            id="firstcontainer"
          >
              <div className="row">
                    <div className="col-md-7 starter-details">
                      <h5>Sharad Dutta Acharya</h5>
                      <p style={{ fontSize: "19px" }}>
                        Bull Power Energy Pvt. Ltd
                      </p>
                      <p>
                        Best Solar Panels distributor in India! Reliable supply
                        of high-quality solar modules. Efficient service, prompt
                        delivery, and affordable prices. A trusted partner for
                        all solar needs.
                      </p>{" "}
                      <br /> <br />
                      <div className="star-container">
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-solid fa-star"></i>
                        <i class="fa-regular fa-star"></i>
                      </div>
                    </div>
                    <div className="col-md-5 remake m-auto">
                      <img
                        src={test_img4}
                        alt="client-4"
                        title="client-4"
                        id="firstimg"
                        width={"93%"}
                        height={"100%"}
                      />
                    </div>
                  </div>
          </div>
        </SplideSlide>
      </Splide>
    </div> */}
        </div>
      </section>
    </>
  );
}
