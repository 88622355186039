import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import blog_img from "../images/Oneklick-blog.webp";
import post_img from "../images/Authorized-Distributor-in-india.webp";
import "./newblog.css?v=1.1";

import { Helmet } from "react-helmet";
import blogtem from "./blog-page";
import { Link } from "react-router-dom";
import "./blog-page";
export default function newblog() {
  useEffect(()=>{
    window.scrollTo(
      {
        top:0,
        left:0,
        behavior:'instant',
      }
    )
    
   },[] )

  
  return (
    <div>
      <Helmet >
      <title >Solar Panels Energy Blog | Oneklick Techno Renewable </title>
        <meta
        data-react-helmet="true"
          name="description"
          content=" Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future."
        />
        <meta
          name="keywords"
          content=" solar panel distributor in india, solar panels blog, solar modules article, renewable solar energy, solar panels, solar modules prices, solar panels EPC services"
        />
        <link rel="canonical" href="https://oneklick.in/blog" />
        <meta
          name="twitter:title"
          content="Solar Panels Energy Blog | Oneklick Tecno Renewable "
        />
        <meta
          name="twitter:description"
          content=" Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future."
        />
        <meta
          property="og:title"
          content="Solar Panels Energy Blog | Oneklick Tecno Renewable "
        />
        <meta
          property="og:description"
          content="Read the latest articles on the solar industry and renewable solar energy sector here. Oneklick publishes top solar energy and solar solutions blogs for a greener future."
        />
        <meta property="og:url" content="https://oneklick.in/blog" />
      </Helmet>

      <Navbar />
      <div class="col-md-12 warehouse-main-container">
        <div className="row row1">
          <div className="col-md-6 firstCol">
            <h1 className="aboutHeading">Blog</h1>
            <div className="button1">
              <a href="">
                Home<i class="fa-solid fa-angle-right"></i>
                <span>Blog</span>
              </a>
            </div>
          </div>
          <div className="col-md-6 secondCol">
            <img
              className="image img-fluid"
              src={blog_img}
              alt="oneklick-blog-img"
              title="oneklick-blog-img"
            />
          </div>
        </div>
      </div>

      <div className="newblog-parent col-md-12">
        <div className="newblog-container col-md-10">
          <img
            src={post_img}
            className="img-fluid"
            alt="modules-img"
            title="modules-img"
          />
          <div className="blog-header-title">
            <h3>
              OneKlick is an Authorised Distributor of Jinko Solar Module & Solplanet Inverters.
            </h3>
            <p>
              OneKlick is a leading solar module authorized distributor. We
              offer high-quality modules from globally recognized brands like
              Jinko Solar Module, Solplanet Inverters. We strive to
              provide our clients with cutting edge solar technologies and
              ensure that our modules are built to the highest standards of
              quality and reliability. Authorized distributor of Top Solar
              Module Manufacturers.
            </p>
            <li>Authorized distributor of Top Solar Module Manufacturers</li>
            <li>Competitive Pricing</li>
            <li>Strong and Reliable distribution network</li>
            <li>Smooth and easy order process</li>
            <li>On Time Deliveries</li>
            <li>Commitments delivered</li>
            <li>
              Team of Experienced professionals with Ex-IITian as one of the
              founder
            </li>
          </div>
          <div className="blog-header-title col-md-8">
            <h3>
              Tanmay Rishi Shah&#128516;: The Charismatic Child Actor and Brand
              Ambassador for Oneklick Techno Renewable Pvt Ltd...
            </h3>
            <Link to="/blog-page/tanmay" id="tanmayid">
              Read More
            </Link>
          </div>
          <div className="blog-header-title col-md-8">
            <h3>
              Harnessing Solar Power: Solplanet Inverters and Shadesol
              Technology...
            </h3>
            <Link to="/blog-page/solplanet" id="solplanetid">
              Read More
            </Link>
          </div>
          <div className="row ">
            <div className="left-newblog-container col-md-8"></div>

            <div className="right-newblog-container col-md-4">
              <div className="inner-container"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
