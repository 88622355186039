import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Product from "./components/products";
import Contact_page from "./components/contact_page";
import AboutPage from "./components/About";
import WarehousePage from "./components/warehouse";
import Newblog from "./components/blog";
import JinkoSolar from "./components/campain/Jinko-Solar";
import CampaignJinko from "./components/campain/Solex-Solar";
import PageNotFound from "./components/PageNotFound";
import Blogtem from "./components/blog-page";
import Solardatasheet from "./components/solardatasheet";
import Mainsolplanet from "./components/solplanet/Mainsolplanet";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Helmet>
          <title> title </title>
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@Oneklick Techno" />
          <meta name="twitter:creator" content="@Oneklick Techno" />
          <meta name="twitter:url" content="https://oneklick.in"/>
          <meta property="og:type" content="website"/>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
            integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
            crossorigin="anonymous"></script>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-LW7M8VNV4L"></script>
          <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
          <script src="https://kit.fontawesome.com/a7d20ff54b.js" crossorigin="anonymous"></script>
        </Helmet>

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/products" element={<Product />}></Route>
          <Route path="/blog" element={<Newblog />}></Route>
          <Route path="/contact" element={<Contact_page />}></Route>
          <Route path="/About" element={<AboutPage />}></Route>
          <Route path="/warehouse" element={<WarehousePage />}></Route>
          <Route path="/jinko-solar" element={<JinkoSolar />}></Route>
          <Route path="/solex-solar" element={<CampaignJinko />}></Route>
          <Route path="/blog-page/tanmay" element={<Blogtem />}></Route>
          <Route path="/blog-page/solplanet" element={<Blogtem />}></Route>
          <Route path="/solar-product" element={<Solardatasheet />}></Route>
          <Route path="/solplanet" element={<Mainsolplanet />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
