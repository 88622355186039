import React, {  useState ,useEffect} from 'react'
import "../Navbar.css?v=1.1"
import "./products"
import "./contact_page"
import oneklicklogo from "../images/oneklick-logo.webp"
import 'react-dropdown/style.css';
import "./campain/Jinko-Solar"
import "./campain/Solex-Solar"
import { Navbar } from 'rsuite'
import { Link, useLocation } from 'react-router-dom'
import "./About"


const SideNavBar = () => {
  function open() {
    document.getElementById("sidebar").style.display = "block";
    
  }

  function close(){
    document.getElementById('sidebar').style.display = 'none'
  }
  
  

  function CloseNavbar() {
    const navbar_value = document.getElementById("sheet-redial-items");
    const prdbtn = document.getElementById('productbtn')
    const sidebar = document.getElementById('sidebar')
    if (navbar_value.style.display === "block") {
      navbar_value.style.display = "none";
      sidebar.style.display = "block";
     
    } else {
      navbar_value.style.display = "block";
      sidebar.style.display = "block"
     
      
    }
  }

const [isopen , setisopen] = useState(false)

// const loc  = window.location.href;

//  if(window.location.href.length >23  &&  loc[loc.length-1] === "/"){
//   window.location.href = loc.substring(0,loc.length-1);

// }
// else if(window.location.href.length <22 ){
//   window.location.href = window.location.href
// }


// if(window.location.href.length >22  &&  loc[loc.length-1] === "/"){
//   window.location.href = loc.substring(0,loc.length-1);

// }
// else if(window.location.href.length <22 ){
//   window.location.href = window.location.href
// }




  return (
    <>
      <Navbar/>
      <nav id="firstNav" className=''>
        <div id='upperRow'>
          {/* {crumb} */}
          <ul >
            <a href='mailto:sales@oneklick.in'><li><i class="fa-regular fa-envelope"></i>&nbsp; <span>sales@oneklick.in</span></li></a>
           <a href='tel:+919511568993'> <li><i className="fa-solid fa-phone" style={{color:"wheat"}}></i>&nbsp;<span>+91–9511568993</span></li></a>
          </ul>
          <ul>
          <li ><a href="https://www.facebook.com/OneKlickTechnoRenewable" target='_blank' rel='noopener' title='facebook' ><i className="fa-brands fa-facebook-f"></i></a></li>
          <li ><a href="https://twitter.com/OneklickTechno" target='_blank' rel='noopener' title='twitter'><i className="fa-brands fa-twitter"  title='twitter' ></i></a></li>
            <li><a href="https://www.linkedin.com/company/oneklicktechno" target='_blank'   rel='noopener' title='linkedin'><i className="fa-brands fa-linkedin-in "></i></a></li>
            <li><a href="https://www.instagram.com/oneklicktechno/" target='_blank'  rel='noopener' title='instagram'><i className="fa-brands fa-instagram"></i></a></li>
          </ul>

        </div>

        <div id='lowerRow'>

          <div ><a href="/"><img src={oneklicklogo} style={{width:"27%",height:'auto'}} alt="Oneklick-logo" title="Oneklick-logo" className="site-logo-image" /></a></div>
          <div >
            <ul>
             
              <li className='m-auto'><Link to='/'> HOME</Link></li>
              <li className='m-auto'><Link to='/about'> ABOUT</Link> </li>
              <div className="container-container" id= "dropdown-li" >
              <li className='dropdown-toggle p-3'  onClick={()=> {setisopen(!isopen)}} id='con' >PRODUCT</li>
              </div>
              <li className='m-auto'> <Link to='/warehouse'> WAREHOUSE</Link></li>
              <li className='m-auto'>  <Link to='/contact'> CONTACT</Link></li>
              <li className='m-auto'> <Link to='/blog'> BLOG</Link></li>
             
            </ul>
          </div>
          
         {isopen && (<div className="subemenu col-md-1" id='submenus'>
              
              <Link to="/jinko-solar"><p>Jinko Solar</p></Link>
              <Link to="/solplanet"><p>Solplanet</p></Link>
              <Link to="/solex-solar"><p>Solex</p></Link>
              <Link to="/solar-product"><p>Datasheets</p></Link>
              <Link to="/products"><p>Offer</p></Link>
    
     
              {/* <a href="./products"><p>Solplanet Inverter</p></a> */}
           
          </div>
         )}
        </div>
      </nav>
      <nav id='secondNav' style={{ width: "100vw" ,position:'relative' }} className='bg-dark col-md-12'>
        <div id='upperRow'>
          <ul >
            <li> 
            <a href='mailto:sales@oneklick.in'><i className="fa-regular fa-envelope"></i>&nbsp; sales@oneklick.in</a>
            </li>
            <li><i className="fa-solid fa-phone"></i>&nbsp;<span>+91–9511568993</span></li>
          </ul>

        </div>
        <div id='secondRow1'>
          <div ><a href="/"><img src={oneklicklogo}  style={{width:"27%"}} alt="oneklick-logo" title='oneklick-logo' className="site-logo-image" /></a></div>
          <div onClick={open} style={{ fontSize: '30px' }}><button><i style={{ color: "white" }} className="fa-solid fa-bars"></i></button></div>
        </div>

      </nav>
      <div id="sidebar"  className="sidebar">
        <i className="fa-solid fa-xmark" id='sidebarIcon' onClick={close}></i>
        <ul>
          <li><a href="/">HOME</a> </li>
          <li><a href="/about">ABOUT US</a></li>
          <li  onClick={CloseNavbar}style={{cursor:'pointer'}}><li style={{color:'white'}} className='dropdown-toggle navbar-sheet-title p-0' id='productbtn'>PRODUCT </li></li>
         
         <div id='sheet-redial-items' style={{display:"none"}} >
              <Link to="/jinko-solar"><p  style={{paddingTop:'20px'}} >Jinko Solar</p></Link>
              <Link to="/solplanet"><p>Solplanet</p></Link>
              <Link to="/solex-solar"><p>Solex</p></Link>
              <Link to="/solar-product"><p>Datasheets</p></Link>
              <Link to="/products"><p>Offer</p></Link>
             
            

         </div>
          <li><a href="/warehouse">WAREHOUSE</a></li>
          <li><a href="/contact">CONTACT US</a></li>
          <li><a href="/blog">BLOG</a></li>
          
        </ul>
        
      </div>
    </>
  )
}

export default SideNavBar;