import React from "react";
import womenimg from "../../images/womenimg.webp";
import solproductone from "../../images/1-_250kw_central_standalone.webp";
import solproductsecond from "../../images/3-utility_3.webp";
import solarproductthird from "../../images/7-UTILITY_3-10.webp";
import solarproductfour from "../../images/8-UTILITY60KW.webp";
import PlanetParallax from "./solplanetparallax";
import solarproductfive from "../../images/9-AISWEI.webp";
import Cardsection from "./solplanetcard";
import shedsol from "../../images/Solplanet ShadeSol.webp";
import { Link } from "react-router-dom";
import Faq from "../Faq";
import Faqsecond from "../usebleaccordin";
import visondot from "../../images/background Wave.webp"
import inverterimg from "../../images/invertervision.webp"
import Partnership from "./Partnership";
const visionsetion = () => {
  const detail = [
    {
      question: "1. ShadeSol Technology & 10 Year Warranties",
      answer:
        "ShadeSol Technology offers cutting-edge solutions with unparalleled 10-year warranties, ensuring long-lasting and reliable performance for your renewable energy needs.",
    },
    {
      question: "2. Enhanced reliability and durability, ensuring a long-lasting solar energy solution",
      answer:
        "Optimized engineering and robust materials guarantee an extended lifespan for a resilient solar energy system, offering enduring sustainability",
    },
    {
      question: "3. Optimized energy yield, maximizing your solar investment",
      answer:
        "Efficient panel placement and tracking systems enhance solar yield, ensuring maximum return on investment",
    },
    {
      question: "4. Higher AC Voltage range",
      answer:
        "IAC voltage range refers to the variation in voltage levels that an electrical system can handle. A higher AC voltage range indicates greater tolerance for fluctuations in voltage, ensuring stable operation of connected devices.",
    },
    {
      question: "5. Higher input Current each MPPT, ideal for bifacial and large area PV modules",
      answer:
        "Higher input current per MPPT optimizes energy harvest in bifacial panels and large PV arrays, enhancing overall system efficiency.",
    },
    {
      question: "6. TYPE II Surge Protection for DC&AC & Fuse free design",
      answer:
        "Comprehensive TYPE II surge protection for both DC and AC systems with a innovative fuse-free design, ensuring robust and uninterrupted electrical safety.",
    },
    {
      question: "7. Support 7*24H monitoring & Upto 10 MPPT´s for flexible PV array design for higher yields",
      answer:
        "24/7 monitoring ensures constant oversight; Accommodate up to 10 MPPTs for optimized PV array configurations and increased yields.",
    },
    {
      question: "8. Wide MPP voltage range 150V-1000V & 150% PV array oversizing for higher yields",
      answer:
        "Wide MPP voltage range (150V-1000V) maximizes energy harvesting. 150% PV array oversizing ensures optimal yield in varying conditions.",
    },
    
    
  
  ];
  return (
    <div className="col-12 col-md-12 col-lg-12 bg-light">
        <div className="header-subtitle col-md-8 mt-5 mx-auto">
        <h2
          className="col-md-12 co-12  text-center px-2 m-2 "
          data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true"
          style={{background:'#262645'}}
        >
        How's Shadesol Technology work in Solplanet inverters
        </h2>
        </div>
       
        <div className="shedsol bg-light col-md-10 col-12 col-md-12  ">
        <div className="row">
        <div className="col-md-6 col-12 col-lg-6 shadesol-left">
        <img src={shedsol} className="img-fluid" width="800px" alt=" Shadesol-Technology-work" 
        title=" Shadesol-Technology-work" data-aos-duration="800"
          data-aos="zoom-in"
          data-aos-once="true"
          />
        </div>
        <div className="col-md-6 col-12 col-lg-6 shadesol-right">
        <div
        className="col-md-12 header-section mb-2
         flex-column  d-flex justify-content-center align-items-center "
        
      >
      
        </div>
        <div className="px-3" data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true">
          <p className="m-0">Shadesol technology in Solplanet inverters works by constantly scanning the full of the PV curve and fiding the Global Maximum Power Point(GMPP).The GMPP is the point at which the <Link to="/"><b className="text-dark">solar panels </b></Link> are producing the most power, even if some the panels are shaded. this can lead to significant increase in power production,especially in shaded conditions.</p>
          <ul>
            <li className="text-dark text-start">Traditional string inverters can only find the Local Maximum Power Point (LMPP)</li> <br />
            <li className="text-dark text-start">The LMPP is the point at which the string of solar panel is  producing this most power, but it may not be the global maximum power point</li> <br />
            <li className="text-dark text-start">In shaded conditions, the LMPP may be significantly lower than the GMPP. this means taht tranditional string inverters may be underperforming by a significant margin.</li>
          </ul>
          </div>
        </div>
        </div>
        </div>

<Partnership/>

        {/* <div className="container d-flex justify-content-center align-items-center flex-column">
        <h2
          className="col-md-9  mt-5 text-center px-2 "
          data-aos-duration="800"
          data-aos="fade-right"
          data-aos-once="true"
          style={{background:'rgb(38, 38, 69)'}}
        >
       Solplanet Solar Inverters in India
        </h2>
        <p
          className="col-md-9 text-start px-4 py-1 mt-3  "
          data-aos-duration="800"
          data-aos="fade-left"
          data-aos-once="true"
        >
          <ul  className="fs-6 ">
            <li>
            Solplanet Solar Inverters are renowned for their efficiency, reliability, cutting-edge Shadesol technology, and excellent warranties
            </li>
            <br />
            <li>
            They play a crucial role in converting the direct current (DC) electricity generated by <a href="/"><b className="text-dark">solar panels </b></a> into usable alternating current (AC) electricity for your home or business.
            </li>
            <br />
            <li>
            Solplanet Inverters have made a mark in the Indian solar market due to their impressive performance and innovative features. 
            </li>
            <br />
            <li>
            From grid-tie to off-grid and hybrid inverters, Solplanet offers a diverse range of options that cater to various solar installations and energy needs.
            </li>
            <br />
            <li>
            Oneklick offers a wide range of Solplanet solar Inverters. You can easily Buy Top Solplanet solar Inverters, with a strong reputation, extensive experience, and a commitment to quality and customer satisfaction.
            </li>
          </ul>
        </p>
        </div> */}
  
      <div className="vision-container col-md-12 col-12 ">
    <img src={visondot} className="img-fluid vision-dott" title="Solplanet-Inverters"  alt="Solplanet-Inverters"  />
        <div className="row justify-content-center col-md-12 ">
          <div className="left-vision-container col-md-12  col-sm-10 col-xs-12 col-lg-7 p-0 d-flex justify-content-between flex-column">
            <div className="vision-align">

 <h3 className="fs-3 fw-bold pl-3 text-dark text-start mb-3 "  data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true" style={{zIndex:4,position:'relative'}}>The Upsides of Utilizing SolPlanet Inverters</h3>
           
           <Faqsecond detail={detail}  /> 


            </div>
          </div>
          <div className="right-vision-container col-md-8 col-sm-12  " >
            <img src={womenimg} className="img-fluid" width={"100%"} alt="solplanet-inverter" title=" solplanet-inverter" data-aos-duration="800"
          data-aos="zoom-in"
          data-aos-once="true" />
          </div>
        </div>
      </div>
     

     
      {/* Points section */}

      {/* <div className="col-12 col-md-12 d-flex justify-content-center align-items-center">
      <div className="col-md-10">
      <div className="row">
      <div className="left-vison-sec col-md-6 col-12">
      <Faqsecond detail={points}/>
      </div>
      <div className="right-vison-sec col-md-6 d-flex justify-content-center align-items-center  m-auto">
        <img src={inverterimg} alt="" className="img-fluid " style={{maxHeight:'450px',height:'450px'}} />
      </div>
      </div>
      </div>
      </div> */}
      {/* <div className="child-container">
        <div className="col-md-8 header-section">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"
            style={{ marginTop: "60px" }}
            className="px-1 hide-tit"
          >
            Solplanet Inverters Features
          </h2>
          <p data-aos-duration="800" data-aos="fade-left" data-aos-once="true">
            Solplanet Solar inverters come with various features to enhance
            their performance and functionality.
            <li className="mt-3">
              <b>Solplanet inverters Efficiency:</b> These{" "}
             
              High efficiency is important for solar inverters to minimize
              energy loss during the conversion process.
            </li>
            <li>
              <b>Grid integration:</b>
              Grid-tied inverters synchronize with the utility grid, allowing
              you to send excess energy back to the grid and potentially earn
              credits or incentives through net metering programs.
            </li>
            <li>
              <b>Battery Compatibility: </b>
              The inverter is designed to work seamlessly with energy storage
              systems (batteries). This feature enables you to store excess
              solar energy for use during periods of low sunshine or power cuts.
            </li>
            <li>
              <b>Design: </b>Inverters are smaller, lighter, and more efficient
              than traditional transformer-based models. They are often liked
              for their low energy loss and compact size.
            </li>
            <li>
              <b>Safety Features: </b>Inverters include safety features like
              anti-islanding protection (to prevent sending power to the grid
              during outages) and ground fault detection to ensure safe and
              reliable operation.
            </li>
          </p>
        </div>
      </div>
      <div className="child-container">
        <div className="col-md-8 header-section m-0">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"
            style={{ marginTop: "20px" }}
            className="px-1 hide-tit"
          >
            Benefits of Solplanet Inverters
          </h2>
          <p data-aos-duration="800" data-aos="fade-left" data-aos-once="true">
          Solplanet Solar inverters offer several key benefits that contribute to the
            overall efficiency and effectiveness of solar energy utilization.
            <li className="mt-3">
              <b>Improve efficiency:</b> These{" "}
              
              Solplanet inverters maximize the energy output of solar panels by
              operating at or near their peak efficiency.
            </li>
            <li>
              <b>Environmental Benefits:</b>
              Solplanet inverters contribute to the reduction of greenhouse gas
              emissions and environmental pollution.
            </li>
            <li>
              <b>Longevity and Durability: </b>
              Solplanet inverters are designed to operate quietly, making them
              suitable for residential installations where noise may be a
              concern.
            </li>
            <li>
              <b>Remote Control and Diagnostics: </b>Solplanet inverters offer remote
              control and diagnostic capabilities, allowing you to troubleshoot
              issues and adjust settings without physical access to the
              inverter.
            </li>
          </p>
        </div>
      </div> */}
     
      <div className="child-container m-0 bg-light ">
        <div className="col-md-12 col-lg-9 col-sm-12 header-section p-4  ">
          <h2
            data-aos-duration="800"
            data-aos="fade-right"
            data-aos-once="true"

            className="px-1 hide-tit bg-dark text-light"
          >
            16 Years Know-how 
in PV Research & Development

          </h2>
          </div>
      </div>
          <div className="timeline col-md-12 col-12 bg-light">
            <div className="col-md-12 col-12 container  d-flex justify-content-center flex-column align-items-center px-4">
              <div className="col-12 col-md-10 subtitle-con">
                <div className="col-md-12 col-lg-6  col-sm-12 first-timeline">
                  <div className="stateline"></div>

                  <div className="timeline-round">
                    <p className="year">2010</p>
                  </div>
                  <div className="line-one"></div>
                  <div className="line-two"></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center" >
                    <img
                      src={solproductone}
                      className="img-fluid"
                      width={"60px"}
                      alt="centeral-standalone-inverter"
                      title="centeral-standalone-inverter"
                     
                    />
                    <div>
                      <p className="m-0 text-light">Central Standalone</p>
                      <p className="m-0 text-light">500KW</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-sm-12   second-timeline" style={{top:'-15px'}}>
                  <div className="timeline-round-second" style={{background:'rgb(39 57 202)'}}>
                    <p className="year-second">2011</p>
                  </div>
                  <div className="second-line-one" style={{background:'rgb(39 57 202)'}}></div>
                  <div className="second-line-two" style={{background:'rgb(39 57 202)'}}></div>
                  <div className="second-timeline-cont  d-flex justify-content-evenly align-items-center" style={{background:'rgb(39 57 202)'}}>
                    <img
                      src={solproductsecond}
                      className="img-fluid"
                      width={"60px"}
                      alt="three-phase-string-inverter"
                      title="three-phase-string-inverter"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string Zeversolar
                      </p>
                      <p className="m-0 text-light">17kw</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-10 subtitle-con">
                <div className="col-12 col-md-12 col-lg-6 col-sm-6 first-timeline" style={{top:'-30px'}}>
                  <div className="timeline-round bg-warning">
                    <p className="year">2012</p>
                  </div>
                  <div className="line-one bg-warning"></div>
                  <div className="line-two bg-warning"></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center bg-warning">
                    <img
                      src={solproductsecond}
                      className="img-fluid"
                      width={"60px"}
                      alt="string-inverter-zeversolar"
                      title="string-inverter-zeversolar"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                        Utility three phase string Zeversolar
                      </p>
                      <p className="m-0 text-light">3-10kw</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6 col-sm-6  second-timeline" style={{top:'-45px'}}>
                  <div className="timeline-round-second bg-info">
                    <p className="year-second ">2014</p>
                  </div>
                  <div className="second-line-one bg-info"></div>
                  <div className="second-line-two bg-info"></div>
                  <div className="second-timeline-cont d-flex justify-content-evenly align-items-center bg-info">
                    <img
                      src={solproductone}
                      className="img-fluid"
                      width={"60px"}
                      alt="centeral-standalone-string-inverter"
                      title="centeral-standalone-string-inverter"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                      Central Standalone
                      </p>
                      <p className="m-0 text-light"> 1MW SMA</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-10 subtitle-con">
                <div className="col-md-12 col-lg-6 col-sm-6 first-timeline" style={{top:'-60px'}}>
                  <div className="timeline-round bg-danger">
                    <p className="year">2016</p>
                  </div>
                  <div className="line-one bg-danger"></div>
                  <div className="line-two bg-danger"></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center bg-danger">
                    <img
                      src={solarproductthird}
                      className="img-fluid"
                      width={"60px"}
                      alt="utility-three-phase-string"
                      title="utility-three-phase-string"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                      Utility three phase string
                      </p>
                      <p className="m-0 text-light"> 3-10KW SMA</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 col-sm-6  second-timeline" style={{top:'-75px'}}>
                  <div className="timeline-round-second bg-success" >
                    <p className="year-second ">2017</p>
                  </div>
                  <div className="second-line-one bg-success"></div>
                  <div className="second-line-two bg-success"></div>
                  <div className="second-timeline-cont d-flex justify-content-evenly  align-items-center bg-success">
                    <img
                      src={solarproductfour}
                      className="img-fluid"
                      width={"60px"}
                      alt="utility-three-phase-string"
                      title="utility-three-phase-string"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                      Utility three phase string
                      </p>
                      <p className="m-0 text-light"> 60KW SMA</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-12 col-md-10 subtitle-con">
                <div className="col-12 col-md-12 col-lg-6 col-sm-6 first-timeline" style={{top:'-90px'}}>
                  <div className="timeline-round" style={{background:'#6D41A1'}}>
                    <p className="year">2019</p>
                  </div>
                  <div className="line-one" style={{background:'#6D41A1'}}></div>
                  <div className="line-two" style={{background:'#6D41A1'}}></div>
                  <div className="first-timeline-cont d-flex justify-content-evenly align-items-center" style={{background:'#6D41A1'}}>
                    <img
                      src={solarproductfive }
                      className="img-fluid"
                      width={"60px"}
                      alt="AISWE-inverter"
                      title="AISWE-inverter"
                     
                    />
                    <div>
                      <p className="m-0 text-light">
                      AISWE
                      </p>
                      <p className="m-0 text-light"></p>
                    </div>
                  </div>
                </div>
             
              </div>
              
            </div>
            <div className="col-md-12 header-section mb-0 m-0 py-0  planet-div d-flex justify-content-center align-items-center">
              <h2
                data-aos-duration="800"
                data-aos="fade-right"
                data-aos-once="true"
                className='px-4 col-md-8 col-lg-9 col-12 header-section'
                style={{background:'rgb(38, 38, 69)'}}
                
                >
              
              Accurate Data, Clear Insights, Smart Decisions
              </h2> 
              
            </div>
      <div className="mb-3">
      <Cardsection /> 
      </div>
          </div>
      
    
    </div>
  );
};

export default visionsetion;
