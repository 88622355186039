import React from 'react'
import { Link } from 'react-router-dom'

const solplanetparallax = () => {
  return (
    <div>
        <div className="solplanet-parallax py-3 mb-0 ">
    <p className='partner partner-main fw-bold' style={{color:'wheat'}} data-aos="zoom-in" data-aos-once="true">Partner with OneKlick</p>
    <p className='partner text-center fw-semibold m-0' data-aos="zoom-in" data-aos-once="true" style={{fontSize:'30px',color:'wheat'}}>Select and Get the Right Solar Inverters</p>
    <div className="paragraph-written " style={{display:'flex',justifyContent:"center"}} >
                <div className="row col-md-8">
                <p className='text-center mt-3 mb-2' style={{color:'wheat'}}>OneKlick is the authorized Solplanet inverter distributor in India. We provides a wide range of top-quality solar inverters in India</p>
                </div>
              </div>
    
    <div style={{paddingBottom:"20px",paddingTop:"25px"}} className="vc_btn3-container wpb_animate_when_almost_visible wpb_bounceInUp bounceInUp vc_btn3-center wpb_start_animation animated">
      <Link  className="vc_general p-3 fs-6  vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-modern vc_btn3-color-grey" data-aos="zoom-out" data-aos-once="true" to="/contact" title="">Get in Touch with us</Link></div>
    
    </div>

    </div>
  )
}

export default solplanetparallax
