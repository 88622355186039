import React from "react";
import "./faq.css";

const Faq = ({ detail,section }) => {
  return (
    <>
      <div className="d-flex justify-content-center  align-items-center faq-context m-0 py-0 px-0"
             data-aos-duration="800"
             data-aos="zoom-out"
             data-aos-once="true">
        <div
          className="accordion accordion-flush col-md-10 col-lg-10 "
          id={`accordionFlushExample-${section}`}
        >
          {detail.map((item, index) => {   
            return (
              <div className="accordion-item bg-transparent" key={index}>
                <h4
                  className="accordion-header border-none"
                  id={`flush-headingOne${section}-${index}`} 
                >
                  <button
                    className="accordion-button acc-btn collapsed bg-transparent text-dark"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${section}-${index}`} 
                    aria-controls={`flush-collapse${section}-${index}`} 
                  >
                    {item.question}
                  </button>
                </h4>
                <div
                  id={`flush-collapse${section}-${index}`} 
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${section}-${index}`} 
                  data-bs-parent={`#accordionFlushExample-${section}`} 
                >
                  <div className="accordion-body acc-bdy text-dark text-start ">{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Faq;
