import React from 'react'
import Navbar from "../components/Navbar"
import Footer from './footer'
import { Helmet } from 'react-helmet'
export default function PageNotFound() {
  return (
      <>
       <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
  <Navbar></Navbar>
  
    <div>
    <div class="d-flex align-items-center justify-content-center vh-100 " style={{flexDirection:'column'}}>
        <h1 class="display-1 fw-bold text-dark">Oops!</h1>
        <h1 class="display-1 fw-bold text-dark">404 - Page Not Found</h1>
    </div>
    </div>
    <Footer></Footer>
    </>
  )
}
