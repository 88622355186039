import React from 'react'
import  { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css"
import Difference1 from "../images/Difference1.webp"
import Difference2 from "../images/Difference2.webp"
import Difference3 from "../images/Difference3.webp"
import Difference4 from "../images/Difference4.webp"
import Difference5 from "../images/Difference5.webp"
import Difference6 from "../images/Difference6.webp"

const differenceData = [
    {
        imgSrc: Difference1,
        title: "Customer Centric Approach",
        content: "We always put our customers first and work to provide the best possible experience. We’re constantly improving our process and service, and welcome feedback to help us grow. Our interaction with our clients is a great way to keep up with the latest news and announcements, as well as learn more about how we operate.",
        alt:"Customer-Centric-Approach",
        title:"Customer Centric Approach"
        
    },
    {
        imgSrc: Difference2,
        title: "Strong Distribution Network",
        content: "We have a strong distribution network in India that helps us get our solar products to our customers quickly and efficiently. We have a dedicated team of professionals who handle all aspects of the distribution process, from warehousing to transportation. This ensures that our products are delivered on time and in perfect condition.",
        alt:"Strong-Distribution-Network",
        title:"Strong Distribution Network"
        
    },
    {
        imgSrc: Difference3,
        title: "Team",
        content: "At OneKlick, we have a dedicated team of experienced professionals who are knowledgeable about the latest technology and have vast experience of decades having worked in Solar Industry. Our staff comprises of combination of youth and experience and our director of business development is an IIT Kharagpur alumnus.",
        alt:"Team ",
        title: "Team"
    },
    {
        imgSrc: Difference4,
        title: "Affordable",
        content: "We are committed to providing affordable, reliable solar modules to homes and businesses across India. We are constantly innovating and expanding our product range, so that we can offer you the latest and best technology available at the best prices. Our goal is to make going solar as easy and affordable as possible for everyone.",
        alt:'Affordable',
        title :'Affordable'
    },
    {
        imgSrc: Difference5,
        title: "Mission",
        content: "We are on a mission to provide renewable energy solutions that are affordable and reliable in India. We believe that solar energy is the key to a sustainable future, and we are committed to making it more accessible to everyone. Our team of experts are always available to guide and advise you on the best product for your project.",
        alt:"Mission",
        title: "Mission"
    },
    {
        imgSrc: Difference6,
        title: "Vision",
        content: "We at OneKlick Techno Renewable Pvt. Ltd strongly believe that solar energy is the future. We are committed to providing our customers with the best products and services in the solar industry and strive to be the leading solar module distributor in India. We are constantly innovating and expanding our business to better serve our customers.",
        alt:'Vision',
        title :'Vision'
    }
]

export default function Difference() {

    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

    return (
        <div>
            <div className="differences">
                <div className="section-title" data-aos="zoom-in" data-aos-once="true">
                    <div className='introduction'>
                        <p>WHY ONEKLICK    </p>
                    </div>
                    <div className="header-title col-md-6" style={{width:'100%', display:'flex',justifyContent:"center"}}>
                    <h2><strong> All You Need Is OneKlick </strong></h2>
                    </div>
                    
                </div>
                <div className="paragraph-written" style={{display:'flex',justifyContent:"center"}} >
            <div className="row col-md-8" style={{textAlign:"center",padding:'20px'}}>
            <p>When it comes to your solar modules needs, all you need is OneKlick. We are your <strong> one-stop destination</strong> for reliable and efficient solar solutions. From selecting the perfect solar module to smooth installations and timely deliveries, we've got you covered. <strong> Trust our expertise</strong> and dedication to bring you the best in clean energy, simplifying your solar journey with just OneKlick. Embrace sustainability and a brighter future with confidence, knowing that  <strong>OneKlick has everything you need for your solar power needs.</strong></p>
            </div>
          </div>
                <div className="section-content">
                    <div className='row col-md-11 ' style={{justifyContent:'space-around',margin:'auto'}}>
                        {differenceData.map((item, index) => (
                            <div className="col-md-4  " data-aos="flip-up" data-aos-once="true" key={index}>
                                <div className="product-imgs product-hover" >
                                    <img src={item.imgSrc}  className='img-icon img-fluid pro-imgs'  alt={item.alt} title={item.title} />
                                    <div className='overlay'>
                                        <div className='text'>
                                        </div>
                                    </div>
                                    <h3>{item.title}</h3>
                                </div>

                                <p className='differnces'>{item.content}</p>

                            </div>
                        ))}



                    </div>
                </div>
            </div>
        </div>
    )
}
