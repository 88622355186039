import React from "react";
import "./testimonial.css";
import test_img from "../../images/harsh.webp";
import test_img2 from "../../images/harvinder.webp";
import test_img3 from "../../images/Simran.webp";
import test_img4 from "../../images/Sharad.webp";
import { useState,useEffect } from "react";

const testimonial = () => {
    const context =[
        {
            image:test_img,
            name:"Harsh",
            company :"JD Enerbiz Pvt. Ltd",
            para :"Oneklick is solar panels distributor company in india. We Highly satisfied! Excellent solar panels and greatservice. Oneklick is best distributor! We are Highly recommend for solar modules in India."
        },
        {
            image:test_img2,
            name:"Harvinder Singh",
            company :"Solidus Techno Power Pvt.",
            para :"Oneklick is Reliable solar service, its have provide top-quality panels, and excellent prices. Highly recommend this solar panel distributor!"
        },
        {
            image:test_img3,
            name:"Simran Singh",
            company :" Bliss Solar Power Pvt. Ltd.",
            para :" Exceptional solar module distributor! OneKlick offer a wide range of high-quality Solar modules, efficient service, and competitive Solar panel prices. A reliable partner for all solar needs. Highly recommended!"
        },
        {
            image:test_img4,
            name:"Sharad Dutta Acharya",
            company :"Bull Power Energy Pvt. Ltd.",
            para :"Best Solar Panels distributor in India! Reliable supply of high-quality solar modules. Efficient service, prompt delivery, and affordable prices. A trusted partner for all solar needs."
        },
    ]

    const[value,setValue] =useState(3)
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 768) {
            setValue(1);
          } else if (window.innerWidth <= 1000) {
            setValue(2);
          } else if (window.innerWidth >= 1200) {
            setValue(3);
          }
        };
   
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    

  return (
    <>
    <div className="testi-content-container col-md-12">
              <div className="client-testi col-md-2 ">
                <p className="text-center">Client Testimonial</p>
              </div>
            </div>
      <div className="testi-header-section mt-3">

              <h4 className="text-dark fs-2 text-center">
                Uplifting Spirits,Inspiring Stories:<br /> Heartwarming
                Testimonials from Our Valued Clients{" "}
              </h4>
            </div>
    <div className="parent-swiper">
    <div className="swipe-slide-container p-0 ">
        <swiper-container className="mySwiper" pagination="true" pagination-clickable="true" space-between="30" slides-per-view={value} loop="true">
            
       {
        context.map((item,index)=>{
            return(
                     
            <swiper-slide key={index}>
            <div className="swipe-slide-content" >
                <div className="swipe-card-wrapper ">
                    <div className="swipe-card ">
                        <div className="swipe-image-content">
                            <span className="swipe-overlays"></span>
                            <div className="swipe-card-image">
                                <img className="imggg" src={item.image} alt=""/>
                            </div>
                        </div>
                        <div className="swipe-card-content">
                            <h5 className="test-name">{item.name}</h5>
                            <p className="test-name fs-5">{item.company}</p>
                            <p className="test-description">{item.para}</p>
                        </div>
                    </div>
                </div>
               
            </div>

        </swiper-slide>
            )
        })
       }
           
            
        </swiper-container>

    </div>
   </div>
    </>
  );
};

export default testimonial;
