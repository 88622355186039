import React from "react";
import Navbar from "../Navbar";
import Footer from "../footer";
import "./mainsolplanet.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState, useEffect } from "react";
import Partnership from "./Partnership";
import Sp from "../../images/1.webp";
import solproductone from "../../images/1-_250kw_central_standalone.webp";
import solproductsecond from "../../images/3-utility_3.webp";
import solarproductthird from "../../images/7-UTILITY_3-10.webp";
import solarproductfour from "../../images/8-UTILITY60KW.webp";
import solarproductfive from "../../images/9-AISWEI.webp";
import Faq from "../Faq";
import Vision from "./visionsetion";
import solarplate from "../../images/solarpanel.webp";
import tigerneobipdf from "../../images/JKM565-585N-72HL4-BDV-F4-EN (IEC 2005).pdf";
import plat2 from "../../images/2.webp";
import plat3 from "../../images/3.webp";
import tigerneobipdf2 from "../../images/JKM600-620N-78HL4-BDV-F4-EN (IEC 2005).pdf";
import Solplanetsheet from "./Solsheet";
import img1 from "../../images/aswlt-g3.webp";
import pdf1 from "../../images/aswlt-g3.pdf";
import img2 from "../../images/three-phase-hybrid.webp";
import pdf2 from "../../images/Datasheet-ASW-3-10K-LT-G2-Pro-Series_Global-EN_0323.web_.pdf";
import img3 from "../../images/single-phase-hybrid.webp";
import pdf3 from "../../images/single-phase-hybrid.pdf";
import img4 from "../../images/three_face_25-40.webp";
import pdf4 from "../../images/Datasheet-ASW-ASW-25-40K-LT-G3-Series-Global-EN_0323.web_.pdf";
import img5 from "../../images/asw3000-s.webp";
import pdf5 from "../../images/asw3000-s.pdf";
import img6 from "../../images/aswlt-g2.webp";
import pdf6 from "../../images/aswlt-g2.pdf";

const Solsheet = () => {
  const faqdeatil = [
    {
      question: "Q1 . Where can we get Solplanet inverters ?",
      answer:
        "At Oneklick, you can get Solplanet inverters with features, solar inverters prices, and specifications.",
    },
    {
      question: "Q2 . Why Solplanet For Solar Inverters In India?",
      answer:
        "Top solar inverters in the Indian market, Solplanet stands as a reputed brand. SolPlanet inverters are designed to deliver exceptional performance even in challenging conditions thanks to features like Shade Sol technology and maximum point tracking.",
    },
    {
      question: " Q3. What is the Price of Solplanet Inverter in India?",
      answer:
        "The price of a Solplanet inverters can vary widely depending on several factors, including the type of inverters, its capacity, and additional features. ",
    },
    {
      question: "Q4. What Inverter Is Right for My Home?",
      answer:
        "OneKlick helps you select the right inverter for your solar power system. This can have a significant impact on system performance, energy production, and overall efficiency. The inverter depends on your home and energy needs.",
    },
    {
      question: "Q5. What is Solplanet Inverter Efficiency? ",
      answer:
        "Solplanet inverter efficiency is an important factor to consider when evaluating and designing a solar power system. Inverter efficiency is usually expressed as a percentage and shows how effectively the inverter performs its primary function.",
    },
    {
      question: "Q6. Why do we need Solplanet Solar inverters? ",
      answer:
        "Solar panels generate DC electricity, but home appliances and the grid operate on AC electricity. A solar inverter is necessary to convert DC electricity into usable AC electricity.",
    },
    {
      question: "Q7. What is a Hybrid Solar Inverter?",
      answer:
        "Hybrid solar inverter combines the functions of a standard solar inverter with a battery inverter/charger. It stores excess solar energy in batteries for use during periods of low sunshine or during power cuts.",
    },
    {
      question:
        "Q8. What is the difference between string inverters and microinverters?",
      answer:
        "String inverters are usually installed in a central location and connect multiple solar panels in a series. But Microinverters are installed on each individual panel and convert DC to AC at the panel level. It provides better performance in cases of shading or panel-level mismatch.",
    },
    {
      question: "Q9 . Can I connect my Solplanet inverter to the grid?",
      answer:
        "Yes, grid-tied inverters allow you to connect your solar system to the electrical grid.",
    },
  ];

  const differenceData = [
    {
      sheets: pdf1,
      imgSrc: img1,
      title: "Single Phase String 3 to 5 Kw",
      alt: "Single-Phase-String-3-5-Kw",
      point1: "Support up to 150% DC/AC ratio",
      point2: "Fast installtion with no lid opening necessary",
      point3: " IP65 for outdoor use",
      point4: " Dual MPPT for flexible installtion",
    },
    {
      sheets: pdf2,
      imgSrc: img2,
      title: " Three Phase Hybrid 3 to 10 KW",
      alt: "Three-Phase-Hybrid-Solplanet-Inverter",
      point1: "User friendly app interface",
      point2:
        "Max.20 A input current, ideal for bifacial and large area PV modules",
      point3: " Wide MPP voltage range 150V-1000V",
      point4: " ShadeSol shadow management",
    },
    {
      sheets: pdf3,
      imgSrc: img3,
      title: "Three Phase Hybrid 12 to 20 KW",
      alt: "Three-Phase-Hybrid-12-to-20KW",
      point1: "User friendly app interface",
      point2:
        " 20 A input current, ideal for bifacial and large area PV modules",
      point3: "  Wide MPP voltage range 150V-1000V",
      point4: " ShadeSol shadow management",
    },
    {
      sheets: pdf4,
      imgSrc: img4,
      title: "Three Phase Hybrid 25 to 40 KW",
      alt: "Three-Phase-Hybrid-25-to-40KW",
      point1: "Scheduled EV charging",
      point2: "Adjustable charging power",
      point3: " RFID for user authentication",
      point4: " LED status indicators",
    },
    {
      sheets: pdf5,
      imgSrc: img5,
      title: "Three Phase Hybrid 40 to 45 KW",
      alt: "Three-Phase-Hybrid-40-to-45KW",
      point1:
        "16 A input current, ideal for bifacial and large area PV modules ",
      point2: " 5 MPPT´s for flexible PV array design",
      point3: "Wide MPP voltage range 200V-1000V",
      point4: " ShadeSol shadow management",
    },
    {
      sheets: pdf6,
      imgSrc: img6,
      title: "Three Phase Hybrid 75 to 110 KW",
      alt: "Three-Phase-Hybrid-75-to-110KW",
      point1: " Support 7*24H monitoring ",
      point2: " Quick setup and commissioning with Solplanet Apps",
      point3: " String-level Management",
      point4: "ShadeSol shadow management",
    },
  ];
  return (
    <div>
      <div className="differences mt-4">
        <div className="section-content p-1">
          <div
            className="row col-md-10 col-lg-10 col-11 "
            style={{ justifyContent: "space-around", margin: "auto" }}
          >
            {differenceData.map((item, index) => (
              <div
                className="col-md-4  "
                data-aos="flip-up"
                data-aos-once="true"
                key={index}
              >
                <div className="product-imgs product-hover bg-light">
                  <div className="text-center">
                    <img
                      src={item.imgSrc}
                      height={"250px"}
                      width={"100px"}
                      className="img-icon text-capitalize "
                      alt={item.alt}
                      title={item.title}
                    />
                  </div>
                  <div className="overlay">
                    <div className="text"></div>
                  </div>
                  <h3 className="">{item.title}</h3>
                </div>
                <div className="flex-column d-flex">
                  <div
                    className="differnces flex-column fs-6 mb-0 mt-3"
                    style={{
                      float: "left",
                      display: "flex",
                      alignItems: "flex-start",
                      minHeight: "170px",
                      margin: "auto",
                    }}
                  >
                    <ul>
                      <li className="text-capitalize text-dark text-start">
                        {item.point1}
                      </li>
                      <li className="text-capitalize text-dark  text-start">
                        {item.point2}
                      </li>
                      <li className="text-capitalize text-dark text-start">
                        {item.point3}
                      </li>
                      <li className="text-capitalize text-dark text-start">
                        {item.point4}
                      </li>
                    </ul>
                  </div>
                  <div className="mb-5 mt-2 d-flex justify-content-center align-items-center">
                    <a
                      className="btn text-light downloadsheet"
                      onClick={() => window.open(item.sheets)}
                    >
                      Download Datasheet
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="faq-title mt-3"
        data-aos-duration="800"
        data-aos="zoom-out"
        data-aos-once="true"
      >
        <h3 className="faptitle text-center text-dark fw-bold px-2">
          <span>frequently asked question's  </span>
        </h3>
      </div>
      
        {/* section={1}  */}
          <Faq detail={faqdeatil}   /> 
        </div>
     
  );
};

export default Solsheet;
