import React from 'react'
import '../Footer.css?v=1.1'
import  { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css"
import footer_logo from "../images/oneklick-black-logo.webp"
import { useLocation } from "react-router-dom";
import "./warehouse"
import "./contact"
import"./blog"
import { Link } from 'react-router-dom';
export default function Footer() {

	useEffect(() => {
		AOS.init({ duration: 1000 });
	  }, []);


	  const location = useLocation();
	  const currentroute = location.pathname;
	  var footerContent;
	  if(currentroute === "/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>  &nbsp;  Customers don't expect you to be perfect <br /> <br />
		They do expect you to fix things when they go wrong</p>;
	  }
	  else if (currentroute === "/about"){
		footerContent =  <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Step into Our World of Solar Panels:<br/> <br />
		Where Innovation Meets Vision and Positive Impact. </p>
	  }
	  else if (currentroute === "/about/"){
		footerContent =  <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Step into Our World of Solar Panels:<br/> <br />
		Where Innovation Meets Vision and Positive Impact. </p>
	  }
	  else if(
		currentroute === "/contact"
	  ){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Connect with us today ,<br/> <br />and
		let's start a conversation that sparks progress and possibilities!</p>
	  }
	  else if(
		currentroute === "/contact/"
	  ){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Connect with us today ,<br/> <br />and
		let's start a conversation that sparks progress and possibilities!</p>
	  }
	  else if(currentroute === "/blog"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Explore, Engage, and Enlighten: <br /> <br />
		Our Blog is a Journey of Knowledge and Inspiration </p>
	  }
	  else if(currentroute === "/blog/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Explore, Engage, and Enlighten: <br /> <br />
		Our Blog is a Journey of Knowledge and Inspiration </p>
	  }
	  else if(currentroute == '/warehouse'){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Your Solar Products' Safe Haven: <br /> <br />
			 Our Warehouse, Where Quality Meets Care.</p>
	  }
	  else if(currentroute == '/warehouse/'){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Your Solar Products' Safe Haven: <br /> <br />
			 Our Warehouse, Where Quality Meets Care.</p>
	  }
	  else if(currentroute == "/jinko-solar"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; The future belongs to those who <br /> <br />believe in the beauty of their dreams.</p>
	  }
	  else if(currentroute == "/jinko-solar/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; The future belongs to those who <br /> <br />believe in the beauty of their dreams.</p>
	  }
	  else if(currentroute == "/solex-solar"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Choose SOLEX for the <br /> <br />peace of mind and reliability.</p>
	  }
	  else if(currentroute == "/solex-solar/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Choose SOLEX for the <br /> <br />peace of mind and reliability.</p>
	  }
	  else if(currentroute == "/solar-product/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Innovative solar panels offering sustainable energy solutions for a <br /> <br /> greener future</p>
	  }
	  else if(currentroute == "/solar-product"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Innovative solar panels offering sustainable energy solutions for a <br /> <br /> greener future</p>
	  }
	  else if(currentroute == "/products"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Products</p>
	  }
	  else if(currentroute == "/products/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Products</p>
	  }
	  else if(currentroute == "/solplanet"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Solar For Everybody</p>
	  }
	  else if(currentroute == "/solplanet/"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Solar For Everybody</p>
	  }
	  else if(currentroute == "/blog-page/solplanet"){
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;  Empowering A Greener Future</p>
	  }
	  else if(currentroute == "/blog-page/tanmay"){
		footerContent =<p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp;   A Synergistic Partnership</p>
	  }
	  else{ 
		footerContent = <p><i class="fa-solid fa-quote-left  fa-1x"></i>   &nbsp; Something Is Wrong</p>;
	  }

	useEffect(()=>{
		const  linkbtn =  document.querySelector(".scaleedgelink")
		linkbtn.addEventListener('click',function(){
			window.open("https://scaleedge.in/")
		})
	},[])

	
	

  return (
    <>
  <section class="deneb_cta" id='Footer-Footer'>
	<div class="container">
		<div class="cta_wrapper data-content" data-aos="zoom-out-up" data-aos-once="true" data-aos-duration="1000">
			<div class="row align-items-center ">
				<div class="col-lg-12 ">
					<div class="cta_content" >
						<h3>{footerContent} </h3>
					</div>
				</div>
			
			</div>
		</div>
	</div>
</section>
<footer class="deneb_footer">
	<div class="widget_wrapper">
		<div class="container">
			<div class="row">
				
				<div class="col-lg-4 col-md-6 col-12">
					<div class="widget widegt_about">
						<div class="widget_title">
							<img title="oneklick-logo" src={footer_logo} style={{width:"35%", marginBottom:"5px" , height:'35%'}} data-aos="zoom-out" data-aos-once="true" alt="oneklick-logo"></img>
							<p>All we need is <strong>OneKlick</strong></p>
						</div>
						<ul class="social">
							<li><Link to="https://www.facebook.com/OneKlickTechnoRenewable" target='_blank' rel='noopener' title='facebook' data-aos="zoom-out" data-aos-once="true"><i class="fab fa-facebook-f"></i></Link></li>
							<li><Link to="https://twitter.com/OneklickTechno"  target='_blank' rel='noopener' title='twitter'  data-aos="zoom-out" data-aos-once="true"><i class="fab fa-twitter"></i></Link></li>
							<li><Link to="https://www.instagram.com/oneklicktechno/" target='_blank' rel='noopener' title='instagram'  data-aos="zoom-out" data-aos-once="true"><i class="fab fa-instagram insta"></i></Link></li>
							<li><Link to="https://www.linkedin.com/company/oneklicktechno" data-aos="zoom-out" target='_blank' rel='noopener' title='linkedin'  data-aos-once="true"><i className="fa-brands fa-linkedin-in "></i></Link></li>

						</ul>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 col-sm-12 footer-second-container">
					<div class="widget widget_link">
						<div class="widget_title">
							<h4 style={{fontWeight:700}}>Links</h4>
						</div>
						<ul data-aos="zoom-out" data-aos-once="true">
							<h5><li><Link to="/about">About Us</Link></li></h5>
							<h5><li><Link to="/solar-product">Products</Link></li></h5>
							<div className="product-list ">
								<ul className='m-0 p-0 prl-con justify-content-start col-md-6 col-sm-12  d-flex'>
								<div>
								{/* <h5><li><Link to="/jinko-solar">Jinko</Link></li></h5>
								<h5><li><Link to="/solplanet">Solplanet</Link></li></h5> */}
								</div>
								
								<div className=''>
									<h5><Link to="/solex-solar" className='text-dark fw-semibold fs-6'>Solex</Link></h5>
									<h5><Link to="/jinko-solar" className='text-dark fw-semibold fs-6'>Jinko Solar</Link></h5> 
									<h5><Link to="/solplanet" className='text-dark fw-semibold  fs-6'>Solplanet</Link></h5> 	
									
									<h5><Link to="/solar-product" className='text-dark fw-semibold  fs-6'>Datasheets</Link></h5> 
										
								{/* <h5><li><Link to="/solex-solar">Solex </Link></li></h5> */}
								{/* <h5><li> <Link to="/products">Datasheet</Link> </li></h5> */}
								</div>
								</ul>
							</div>
							<h5><li><Link to="/warehouse">Warehouse</Link></li></h5>
							<h5><li><Link to="/contact">Contact Us</Link></li></h5>
							<h5><li><Link to="/blog">Blog</Link></li></h5>
						</ul>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-12 footer-third-container">
					<div class="widget widget_contact">
						<div class="widget_title">
							<h4 data-aos="zoom-out" data-aos-once="true" style={{color:'black'}}>Contact Us</h4>
						</div>
						<div class="contact_info">
							<div class="single_info" style={{fontSize:'17px'}}>
								<div class="icon" >
									<i class="fas fa-phone " style={{color:"#524a6f"}}></i>
								</div>
								<div class="info">
									<p data-aos="zoom-out" data-aos-once="true"><Link to="tel:+919511568993" className='number-link'>+91-9511568993</Link></p>
								</div>
							</div>
							<div class="single_info" style={{fontSize:'17px'}}>
								<div class="icon">
									<i class="fas fa-envelope "></i>
								</div>
								<div class="info">
									<p data-aos="zoom-out" data-aos-once="true"><Link to="mailto:sales@oneklick.in" className='site-link'>sales@oneklick.in</Link></p>
								</div>
							</div>
						</div>
					</div>
				</div>
				

			</div>
		</div>
	</div>
	<div class="copyright_area">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="copyright_text">
						<p>Copyright &copy; 2020 All Rights Reserved | Powered By <span className='scaleedgelink' style={{'cursor':"pointer"}}> Scaleedge.In </span> </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
    </>
  
  )
}
