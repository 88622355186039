import React, { useEffect } from 'react';
import img6 from "../images/jinko-solar-panel.webp";
import img7 from "../images/novasys.webp";
// import img8 from "../images/axitec-solar-panel.webp";
import img8 from "../images/solplanet Image.webp";
import Solex from "../images/solex-solar-panal.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import "./campain/Jinko-Solar"
import "./campain/Solex-Solar"
import "./products"
import { Link } from 'react-router-dom';


export default function Products() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(()=>{
    window.scrollTo(
      {
        top:0,
        left:0,
        behavior:'instant',
      }
    )
    
   })
  return (
    <>
    <div>
      <div className='products'>
        <div className='section-title  ' data-aos="zoom-in" data-aos-once="true" >
          <div className='introduction'>
            <p>Our Products</p>
          </div>
          <div className="header-title col-md-6" style={{width:'100%', display:'flex',justifyContent:"center"}}>
          <h2><strong> Explore our Extensive Range of High-Quality Solar Modules
           for Industries &  Residential Projects </strong></h2>
          </div>      
        </div>
        <div className="paragraph-written" style={{display:'flex',justifyContent:"center"}} >
            <div className="row col-md-8" style={{textAlign:"center",padding:'20px'}}>
            <p>Discover our wide selection of <strong>premium solar</strong>  modules & solar inverters designed to meet the needs of both <strong> industrial and residential projects.</strong> Our <strong> high-quality solar panels</strong> & solplanet inverters offer cutting-edge technology and exceptional performance, ensuring maximum energy efficiency and reliability. Whether you're looking to power your business or home with clean and sustainable energy, our <strong> extensive range of solar modules</strong> has you covered. Embrace the power of the sun and take a step towards a <strong> greener future</strong> with our top-of-the-line <strong> solar solutions.</strong> Here you can find all popular Solar Products, Solplanet inverters, Solar Panels, Solar PV Modules cost, and renewable Solar panels price in india under one platform. </p>
            </div>
          </div>
        <div className='section-content' >
          <div className='row prastuti-row-1'>
            <div className='col-md-6' data-aos="flip-left" data-aos-once='true'>
              <a href="./jinko-solar" className='product-imgs product-hover'>
                <img src={img6} className='image mw-100' alt='jinko-solar-panel' title='jinko-solar-panel'/>
                <div className='overlay'>
                  <div className='text'></div>
                </div>
                <h3>JINKO SOLAR</h3>
              </a>
              <ul className='products-list'>
                <li>Jinko Solar Presence in <strong>over 140 countries</strong></li>
                <li><strong>100 GW Highest shipment</strong> in the industry</li>
                <li><strong>RE100</strong> – First Solar Module Company</li>
                <li><strong>Top Performer</strong> – PVEL’s 2022 Reliability Scorecard for the 8th Consecutive Time</li>
                <li>“ <strong>Top Brand</strong> PV USA 2021″ by EUPD Research</li>
                <li>“Overall <strong>High Achiever</strong>” in RETC’s PV Module Index Report for Third Consecutive Year</li>
              </ul>
            </div>
            <div className='col-md-6' data-aos="flip-right" data-aos-once='true'> 
              <Link to="/solex-solar"  className='product-imgs product-hover'>
                <img src={Solex} className='image mw-100' alt='solex-solar-panel' title='solex-solar-panel'></img>
                <div className='overlay'>
                  <div className='text'></div>
                </div>
                <h3>SOLEX</h3>
              </Link>
              <ul className='products-list'>
                <li><strong>Solex Solar National Stock Exchange Listed</strong> Company</li>
                <li>Fully <strong>Automatic</strong> Production facility in Solar Panels</li>
                <li>Solar Modules High Efficiency up to <strong>21.3%</strong></li>
                <li>Ultra Clear PID free <strong>EVA</strong></li>
                <li><strong>Solar Modules UV Protected</strong> backsheet</li>
                <li>Best in class Temp Co-efficient In Solar Modules</li>
              </ul>
            </div>
            <div className='col-md-6' data-aos="flip-left" data-aos-once='true' >
              <Link to="/solplanet"  className='product-imgs product-hover'>
                <img src={img8} className='image mw-100'  alt='solplanet-solar-panel' title='solplanet-solar-panel'></img>
                <div className='overlay'>
                  <div className='text'></div>
                </div>
                <h3>Solplanet Inverter</h3>
              </Link>
              <ul className='products-list'>
                <li>Solpalnet Solar Inverters<strong> 10 Years Warranty </strong>  </li>
                <li><strong>32 Amp Max Input Current</strong>- Higher Input Current In Solar Inverters</li>
                <li><strong>AC Voltage Range- </strong>312V-528V</li>
                <li><strong>TYPE II Surge </strong> Protection For DC & AC</li>
                <li>Use <strong> ShadeSol Shadow Technology </strong></li>
                <li>Easy to Install, Reliable and <strong>User Friendly Solar Inverter </strong> </li>
                <li><strong> Solplanet inverters </strong> are available in single-phase, three-phase & hybrid </li>
               
              </ul>
            </div>
            <div className='col-md-6' data-aos="flip-right" data-aos-once='true'>
              <Link to="/solar-product"  className='product-imgs product-hover'>
                <img src={img7} className='image mw-100'  alt='novasys-solar-panel' title='novasys-solar-panel'></img>
                <div className='overlay'>
                  <div className='text'></div>
                </div>
                <h3>NOVASYS GREEN</h3>
              </Link>
              <ul className='products-list'>
                <li><strong>A+A+A+ Class sun simulator</strong> to produce defect-free solar modules</li>
                <li>Advanced <strong>Automated Robotic Production</strong> line to avoid human errors</li>
                <li>Novasys Green Solar panels Consistent <strong>quality</strong> and committed <strong>deliveries</strong></li>
                <li><strong>Highest grade</strong> raw materials from international suppliers</li>
                <li><strong>Automatic Stringer</strong> from team Technic, <strong>Germany</strong> used for excellent stringing</li>
              </ul>
            </div>
            <Link to='/products' className='view' data-aos="zoom-in" data-aos-once="true">
              View All Product
            </Link>
          </div>
        </div>
        
      </div>
    </div>
    </>
  );
}
