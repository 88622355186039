import React from 'react'

const usebleaccordin = ({detail}) => {
  return (
    <div>
        <div className=""
             data-aos-duration="800"
             data-aos="zoom-out"
             data-aos-once="true">
        <div
          className="accordion accordion-flush col-md-12  m-0"
          id="accordionFlushExample"
        >
          {detail.map((item, index) => {
            return (
              <div className="accordion-item acc-item mb-2 bg-transparent  " key={index} >
                <h4
                  className="accordion-header acc-head border-none "
                  id={`flush-headingOne ${index}`}
                  
                >
               
                  <button
                    className="accordion-button acc-btn acc-btn collapsed bg-transparent  fs-6 fw-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`}
              
                    aria-controls={`flush-collapse${index}`}
                    
                  >
                    {item.question}
                  
                   
                  </button>
                </h4>
                <div
                  id={`flush-collapse${index}`}
                  className="accordion-collapse collapse "
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body text-capitalize accod-body acc-bdy text-start text-dark " style={{fontSize:'15px'}}>{item.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
    </div>
  )
}

export default usebleaccordin
