import React, { useEffect, useState } from 'react';
import AOS from "aos";
import "aos/dist/aos.css"
import "./campain/Jinko-Solar"
import "./campain/Solex-Solar"
import './products'
import { Link } from 'react-router-dom';

const distributorData = [
  {
    title: 'Jinko Solar',
    description: 'World Most Bankable & Reliable Solar Module, with a track record of Quality, Reliability, Durability & Consistent performance. #Most sold solar module.',
    href : "./jinko-solar"
  },
  {
    title: 'Solex Solar',
    description: 'Solex Solar is NSE listed company with leading global practices, UV protected Solar backsheet, PID free EVA, and 25 years of reliability in Solar Modules.',
    href: './solex-solar'
  },
  {
    title: 'Novasys Green',
    description: 'Solar panels Made with German Stringer TT 2100 machine with utmost transparency ensuring trustworthy Solar Modules for your project.',
    href :'./solar-product'
  },
  {
    title: 'Solplanet',
    description: 'At Solplanet, we are driven by a simple idea: solar for everybody. Our goal is to create high-quality solar products that are efficient and a delight to use. ',
    href :'./solplanet'
  }

];




export default function Section2() {
  
  useEffect(()=>{
    AOS.init({duration:1000})
    
  }
  
  )

  return (

    <>
       <div>
      <div className='Authorized_distributor fade-in'>
          <div>
            <h1 className='authorized fs-2 text-capitalize' data-aos="zoom-in" data-aos-once="true"><b>Authorized Solar Panels <br/> Solar Intervers Distributor In India</b></h1>
          </div>

          <div className="section-content">
          <div className='row'>
            {distributorData.map((item, index) => (
              <div className='col-md-3  my-col' data-aos="flip-left" data-aos-once="true" key={index}>
               
               <h2> <b> {item.title} </b></h2>
                <p>{item.description}</p>
                <Link to={item.href}>
                  Learn More <i className='fa-sharp fa-solid fa-angles-right'></i>
                </Link>
              </div>
            ))}
          </div>
          </div>
          
        </div>
      </div>
      
    </>
  );
}
