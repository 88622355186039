import React from "react";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import img1 from "../../images/solplanet.webp";
import img2 from "../../images/Jinko.webp";
import img3 from "../../images/Nova.webp";
import img4 from "../../images/solex-1.webp";
import Campain_container from "./Campain_firstcontainer";
import Navbar from "../Navbar";
import Footer from "../footer";
import Swal from "sweetalert2";
import footer_logo from "../../images/oneklick-black-logo.webp"
import { Helmet } from "react-helmet";
import Faq from "../Faq";
export default function Header(props) {
  const faqdeatil = [
    {
      question: " Q1. Who is the best solar module distributor in India ?",
      answer:
        "Oneklick Techno Renewable is a reliable and authorized Jinko Solar Modules Distributor in India. Oneklick has a good team and customer support who can meet your solar panel requirements effectively and efficiently.",
    },
    {
      question: "Q2. What is the difference between N-type and P type Jinko ?",
      answer:
        "The main difference between P-type and N-type solar panels is the number of electrons. N-type solar panels have an efficiency level of 25.7% and P-type panels have an efficiency level of 23.6%.",
    },
    {
      question: "Q3. Why choose Jinko Solar homes or commercials ? ",
      answer:
        "Jinko Solar offers great value considering their efficiency, ratings, and long lifespan with the best prices. You can assured that investing in a quality product from Jinko Solar will pay off over time due to its excellent performance and reliability.",
    },
    {
      question: "Q4. What is the capacity of a Jinko Solar cell ? ",
      answer:
        "Jinko Solar cells produce between 250 watts to 400 watts each per hour. Jinko Domestic solar panel systems typically have a capacity of between 1 kW to 4 kW.",
    },
    {
      question: "Q5. What is the Feature of Jinko Solar Modules ?",
      answer:
        "Jinko Solar modules have Very High Efficiency, Advanced Cell Technologies, long-lasting durability, Bifacial Capability, Anti-PID Technology, good Low Light Performance, and High long-term profitability.",
    },
    {
      question: "Q6. What is the efficiency of Jinko solar panels ?",
      answer:
        "Monocrystalline Solar Panels efficiency of 26.4%. Jinko panels offer the highest efficiency rates and power output.",
    },
  ];

  const { value } = props;

  useEffect(() => {
    const titlehead = document.getElementById("title-head");
    window.addEventListener("scroll", () => {
      const screen = window.scrollY;
      titlehead.style.top = 1.0 * screen + "px";
    });
  });

  const values = [
    {
      image1: img1,
      alt: "solplanet-logo",
      title: "solplanet-logo",
    },
    {
      image1: img2,
      alt: "jinko-logo",
      title: "jinko-logo",
    },
    {
      image1: img3,
      alt: "nova-logo",
      title: "nova-logo",
    },
    {
      image1: img4,
      alt: "solex-logo",
      title: "solex-logo",
    },
    {
      image1: img1,
      alt: "solplanet-logo",
      title: "solplanet-logo",
    },
    {
      image1: img2,
      alt: "jinko-logo",
      title: "jinko-logo",
    },
    {
      image1: img3,
      alt: "nova-logo",
      title: "nova-logo",
    },
    {
      image1: img4,
      alt: "jinko-logo",
      title: "jinko-logo",
    },
  ];

  const mailvalidation = (value) => {
    if (/^^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      return true
    }
    else {
      return false
    }
  }


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [requirment, setRequirment] = useState("");
  // const [message, setMessage] = useState("");
  const [validation, setvalidation] = useState(false);
  // console.log(message)
  // useEffect(() => {
  //   const Fetchoffer = () => {
  //     const btn = document.querySelector('.submitbtns');
  //     const form = document.querySelector('.form-container');

  //     btn.addEventListener('click', (e) => {
  //       e.preventDefault(); 

  //       const value = Math.random().toFixed(2);

  //       if (form.checkValidity()) {
  //         setMessage(`Hurrah you get a discount ${value} % off`);
  //       } else {
  //         setMessage(''); 
  //       }
  //     });
  //   }

  //   Fetchoffer();
  // },[]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [])

  const sendEmail = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (name === "" && email === "" && contact === "" && requirment == "") {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Fill The All Details",
        });
        return;
      } else if (name === "" || /\d/.test(name)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Name',
          text: "Please Enter Your Name",
        });
        return;
      } else if (email === "") {
        Swal.fire({
          icon: "error",
          // title: 'Caution',
          text: "Please  Enter Your Email",
        });
        return;
      }
      if (!mailvalidation(email)) {
        Swal.fire({
          icon: "error",
          text: "Invalid Email",
        });
        return;
      } else if (contact.length < 10 || !/^[9876]\d{9}$/.test(contact)) {
        Swal.fire({
          icon: "error",
          // title: 'Invalid Contact Number',
          text: "Invalid Contact Number",
        });
        return;
      } else if (requirment === "") {
        Swal.fire({
          icon: "error",
          title: "Fill The Module",
          text: "Please Enter Your Requirement",
        });
        return;
      }
    }
    try {
      $.ajax({
        url: "https://appj.scaleedge.in/register",
        type: "POST",
        data: {
          subject: "Enquiry For Jinko Solar",
          product: "",
          name: name,
          email: email,
          contact: contact,
          requirment: requirment,
          message: "",
        },
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        success: function (response) {


          console.log("Email sent successfully");
          Swal.fire("Good job!", "Mail Send Successfully", "success");
          console.log(response);
          setName("");
          setEmail("");
          setContact("");
          setRequirment("");
          setvalidation(false);
        },
        error: function (err) {
          console.log(err);
        },
      });
    } catch (error) {
      console.log(error);
    }
    setvalidation(true);
  };

  return (
    <div>
      <Helmet>
        <title>
          Jinko Solar Authorized Distributor | OneKlick
        </title>
        <meta
          name="description"
          content=" OneKlick is Jinko Solar panels distributor or supplier in India. Buy Jinko solar modules, Bifacial Solar Modules, Jinko Bifacial 530, Jinko Bifacial 545, and N-Type Solar Panels."
        />
        <meta
          name="keywords"
          content=" Jinko Solar distributor in India, Jinko solar distributor, Jinko solar, Jinko monofacial 545, jinko distributor, Jinko Bifacial 545, Jinko Bifacial 530, Jinko Solar Panel Cost in India, Jinko Solar Module Cost in India, Jinko Solar panel distributor, Jinko Solar PV distributor, Jinko Solar module distributor, Jinko solar panels supplier in india, Jinko Photovoltaic panel distributor, Buy Jinko Solar panels online  "
        />
        <link rel="canonical" href="https://oneklick.in/jinko-solar" />
        <meta
          name="twitter:title"
          content="Jinko Solar Authorized Distributor | OneKlick"
        />
        <meta
          name="twitter:description" content=" OneKlick is Jinko Solar panels distributor or supplier in India. Buy Jinko solar modules, Bifacial Solar Modules, Jinko Bifacial 530, Jinko Bifacial 545, and N-Type Solar Panels."
        />
        <meta
          property="og:title" content="Jinko Solar Authorized Distributor | OneKlick"
        />
        <meta
          property="og:description" content=" OneKlick is Jinko Solar panels distributor or supplier in India. Buy Jinko solar modules, Bifacial Solar Modules, Jinko Bifacial 530, Jinko Bifacial 545, and N-Type Solar Panels."
        />
        <meta property="og:url" content="https://oneklick.in/jinko-solar" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: " Q1. Who is the best solar module distributor in India ?",
            text: "Oneklick Techno Renewable Is A Reliable And Authorized Jinko Solar Modules Distributor In India. Oneklick Has A Good Team And Customer Support Who Can Meet Your Solar Panel Requirements Effectively And Efficiently.",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: " Q2. What is the difference between N-type and P type Jinko ?",
            text: "The main difference between P-type and N-type solar panels is the number of electrons. N-type solar panels have an efficiency level of 25.7% and P-type panels have an efficiency level of 23.6%.",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: " Q3. Why choose Jinko Solar homes or commercials ? ",
            text: "Jinko Solar offers great value considering their efficiency, ratings, and long lifespan with the best prices. You can assured that investing in a quality product from Jinko Solar will pay off over time due to its excellent performance and reliability.",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: " Q4. What is the capacity of a Jinko Solar cell ? ",
            text: "Jinko Solar cells produce between 250 watts to 400 watts each per hour. Jinko Domestic solar panel systems typically have a capacity of between 1 kW to 4 kW.",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: "Q5. What is the Feature of Jinko Solar Modules ? ",
            text: "Jinko Solar modules have Very High Efficiency, Advanced Cell Technologies, long-lasting durability, Bifacial Capability, Anti-PID Technology, good Low Light Performance, and High long-term profitability.",
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Question",
            name: "Q6. What is the efficiency of Jinko solar panels ? ",
            text: "Monocrystalline Solar Panels efficiency of 26.4%. Jinko panels offer the highest efficiency rates and power output.",
          })}
        </script>
      </Helmet>
      <Navbar />
      <div className="col-md-12 header-container">
        <div className="main-container col-md-12  img-fluid" alt="jinko-solar">
          {/* <div className="small-icon  col-md-1 d-flex justify-content-start align-items-center ">
         /  <a href="#"><img src={glow} alt="" /></a>
        </div> */}
          <div className="title-contact-fix">
            <div className="titles col-md-6">
              <div className="title-header" id="title-head">
                <h1>JINKO SOLAR</h1>
                <p className="top-para">HIGHEST SOLD SOLAR MODULE</p>
                <p>150GW SOLD AND COUNTING</p>
              </div>
            </div>
            <div className="col-md-4  contact-from-container">
              <div className="contact col-md-12 d-flex justify-content-end align-items-center min-vh-100">
                <div className="second-container"></div>
                <form className="form-data form-container">
                  <p className="text-center h2-content fs-2">Contact Form</p>
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <label htmlFor="inputEmail4">Name</label>
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="inputEmail4"
                        placeholder="Enter The Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <label htmlFor="inputEmail4">Email</label>
                      <input
                        name="email"
                        type="email"
                        class="form-control"
                        id="inputEmail4"
                        placeholder="Enter The Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <label htmlFor="inputPassword4">number</label>
                      <input
                        type="len"
                        name="contact"
                        class="form-control"
                        id="inputPassword4"
                        placeholder="Enter The number"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label htmlFor="inputAddress">Prodcut Requirement</label>
                    <input
                      type="text"
                      class="form-control"
                      name="requirment"
                      id="inputAddress"
                      placeholder="Enter The Requirment"
                      value={requirment}
                      onChange={(e) => setRequirment(e.target.value)}
                      required
                    />
                  </div>

                  {/* <input type="text" readOnly className="col-md-12 rounded-3 readvalue" value={message} /> */}

                  <button
                    type="submit"
                    className="btn btn-primary long-btn mt-3 submitbtns"
                    onClick={sendEmail}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

       

        {/* second section */}
        <div
          className="second-containers col-md-12 px-0"
          style={{ width: "100%" }}
        >
          <marquee
            className="marquee-tag"
            scrollamount="30"
            scrolldelay="61"
            truespeed="true"
          >
            <div className="slider-value">
              {values.map((items, index) => (
                <img
                  src={items.image1}
                  alt={items.alt}
                  key={index}
                  title={items.title}
                  width={"200px"}
                  height={"200px"}
                  className="img-fluid"
                />
              ))}
            </div>
          </marquee>
        </div>
      </div>
      <Campain_container />
      <div className="faq-title mt-3"
        data-aos-duration="800"
        data-aos="zoom-out"
        data-aos-once="true">
        <h3 className="faptitle text-center text-dark fw-bold px-2" ><span>frequently asked question's</span></h3>
      </div>
      <Faq detail={faqdeatil} />
      {/* <Footer /> */}

    
<Footer/>
    </div>
  );
}
