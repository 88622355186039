import React from 'react';
import './index.css?v=1.1';
import App from './App';

import { hydrate, render } from "react-dom";
 
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}






// import React from 'react';
// import './index.css?v=1.1';
// import App from './App';

// import { createRoot } from 'react-dom/client';

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// if (rootElement.hasChildNodes()) {
//   root.hydrate(<App />);
// } else {
//   root.render(<App />);
// }


