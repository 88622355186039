import React, { useEffect, useState } from "react";
import "../App.css?v=1.1";
import Navbar from "./Navbar";
import Video from "./Video1";
import Section2 from "./section2";
import Warehouse from "./Warehouse1";
import Products from "./productss";
import Work_process from "./work_process";
import Partner from "./Partner";
import Contact from "./contact";
import CustomModal from "./CustomModal";
import Difference from "./difference";
import Footer from "./footer";
import { Helmet } from "react-helmet";


const WarehousePage = () => <h1>Welcome to the Home Page</h1>;

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
    const timehandle =()=>{
      const showtime = setTimeout(() => {
        setModalIsOpen(true)
      }, 10000);
      return ()=> clearTimeout(showtime)
    }

    useEffect(()=>{
      timehandle()
    },[]) 


    const closeModal = () => {
        setModalIsOpen(false);
    };


    // useEffect(()=>{
    //   window.scrollTo(
    //     {
    //       top:0,
    //       left:0,
    //       behavior:'instant',
    //     }
    //   )
      
    //  })
  
  return (
    <>
  
  
      <Helmet >
        <title>Your Trusted Solar Module & Inverter Supplier | Oneklick</title>
        <meta name="description" content="Oneklick is authorized solar modules distributor & Solplanet inverters supplier in India. Buy solar inverters, solar power systems, & solar panels for homes, rooftops, and commercial spaces."/>
        <meta name="keywords" content="Solar Panels Distributor in India, Solar module Distributor in india, Solar panel prices, Solar Modules, Solar PV modules solutions, Solar Panel supplier in india, solar panel subsidy, Jinko solar panels, Solex solar panels, Axitec solar panels, Novasys solar panels"/>
        <link rel="canonical" href="https://oneklick.in/" /> 
        <meta name="twitter:title" content="Your Trusted Solar Module & Inverter Supplier | Oneklick" />
        <meta name="twitter:description" content="Oneklick is authorized solar modules distributor & Solplanet inverters supplier in India. Buy solar inverters, solar power systems, & solar panels for homes, rooftops, and commercial spaces." />
        <meta property="og:title" content="Your Trusted Solar Module & Inverter Supplier | Oneklick"/>
        <meta property="og:description" content="Oneklick is authorized solar modules distributor & Solplanet inverters supplier in India. Buy solar inverters, solar power systems, & solar panels for homes, rooftops, and commercial spaces."/>
        <meta property="og:url" content="https://oneklick.in/" />
      </Helmet>
  
      <CustomModal isOpen={modalIsOpen} closeModal={closeModal} />
      <Navbar />
      <Video />
      <Section2 />
      <Products />
      <Warehouse component={WarehousePage} />
      <Partner />
      <Work_process />
      <Difference />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
