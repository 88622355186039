import React from "react";
import sma from "../../images/sma.webp"
import jasolar from "../../images/jasolar.webp"
import jinkosolar from "../../images/jinksolar.webp"
import joly from "../../images/Jolywood-Logo.webp"
import siemens from "../../images/Siemens.webp"
import trinna from "../../images/trinna.webp"
import chint from "../../images/chinsolar.webp"
import longi from "../../images/longi.webp"
import { Link } from "react-router-dom";
import Solplanetparallax from "./solplanetparallax";
const Partnership = () => {
    const imgcontent = [
        {
            img:sma,
            alt:"sma-logo",
            title: "sma-logo"
        },
        {
            img:jasolar,
            alt:"jasolar-logo",
            title: "jasolar-logo"
        },
        {
            img:jinkosolar,
            alt:"jinkosolar-logo",
            title: "jinkosolar-logo"
        },
        {
            img:joly,
            alt:"jolywood-logo",
            title: "jolywood-logo"
        },
        {
            img:siemens,
            alt:"siemens-logo",
            title: "siemens-logo"
        },
        {
            img:trinna,
            alt:"trinna-logo",
            title: "trinna-logo"
        },
        {
            img:chint,
            alt:"chint-logo",
            title: "chint-logo"
        },
        {
            img:longi,
            alt:"longi-logo",
            title: "longi-logo"
        },
        
    ]
  return (
    <>
     <Solplanetparallax />
      <div className="partnership-container col-12 col-md-12 col-lg-12 py-3 d-flex justify-content-center align-items-center">
        <div className="col-md-10 col-12 col-lg-10 ">
        <div className="row">
          <div className="partner-left-container col-12 col-md-6 col-lg-6">
            <p className="fs-1 para-partner fw-bold lh-sm p-4 text-capitalize">Solid partnerships with industrial giants</p>
          </div>
          <div className="parter-right-container col-12 col-md-6 col-lg-6">
          <p className="p-4 para-partner fs-6">Solplanet's holding company AISWEI has been cooperating with industrial giants such as SMA, SIEMENS, LONGI, Chint, Trina Solar, Jolywood, Skyworth, SPIC, <Link to="/jinko-solar" className="text-dark fw-bold">Jinko Solar</Link> , JA Solar, TCL, Haier, TBEA etc. and has become their core supplier.</p>
          </div>
        </div>
        <div className="partner-collabration ">
            <div className="d-inline sub-partner  ">
               {
                imgcontent.map((item,index)=>{
                    return(
                        <img src={item.img} key={index} alt={item.alt} title={item.title}  className="img-fluid m-2 col-3 col-md-1 "  /> 
                    )
                })
               }
            </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Partnership;
